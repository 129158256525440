.footer-area{
    padding: 50px 0;
    background-color: black !important;
    bottom: 0;
    width:100%;
    text-align: left;
}

h1 {
    font-size: 60px;
}

li{
    list-style: none;
    margin-top: 12px;
}

a:link { 
    color: white; 
    text-decoration: none; 
} 

a:visited { 
    color:white; 
    text-decoration: none; 
} 

a:hover { 
    color: white; 
    text-decoration: none; 
}

.footer-logo{
    margin-bottom: 40px;
}

.footer-widget h4 {
    font-size: 25px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 25px;
}

.justify-content-center {
    -ms-flex-pack: center!important;
    justify-content: center!important;
}

.single-input input {
    width: 100%;
    border: 0;
    font-size: 18px;
    color: #707070;
    background: none;
    border-bottom: 1px solid #ADB8BC;
    margin-bottom: 24px;
}

.btn:not(:disabled):not(.disabled) {
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 32px;
    background: #f8c828;
    border-style: none;
    padding-right: 25px;
    padding-left: 25px;
    margin-top: 0px;
}

.btn:not(:disabled):not(.disabled) {
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 32px;
    background: #f8c828;
    border-style: none;
    padding-right: 25px;
    padding-left: 25px;
    margin-top: 0px;
}

