.contact-header{
    background-image: url('../../assets/img/cbg.jpg');
    height: 50vh !important;
    color: #fff;
    background-size: cover; 
    background-position: center;
    background-blend-mode: overlay;
    background-color:#000000a9;
}

.card {
    background-color: #FFF;
    border-radius: 25px;
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
    padding: 40px;
    z-index: 0;
}

#progressbar {
    margin-bottom: 30px;
    overflow: hidden;
    color: lightgrey;
    padding-left: 0px;
}

fieldset.show {
    display: block;
}

.radio {
    display: inline-block;
    border-radius: 0;
    box-sizing: border-box;
    cursor: pointer;
    color: #BDBDBD;
    font-weight: 500;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    filter: grayscale(100%);
}

.radio:hover {
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1)
}

#next1 {
    background-color: #7ce3e8;
    color: black;
    border-color: #7ce3e8;
}

.radio.selected {
    border: 1px solid #fdaed1;
    box-shadow: 0px 8px 16px 0px #eeeeee;
    color: #29B6F6 !important;
    -webkit-filter: grayscale(0%);
    -moz-filter: grayscale(0%);
    -o-filter: grayscale(0%);
    -ms-filter: grayscale(0%);
    filter: grayscale(0%);
}

.card-block {
    border: 1px solid #CFD8DC;
    width: 45%;
    margin: 2.5%;
    padding: 20px 25px 15px 25px;
}

.image-icon {
    width: 100px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}

.fit-image {
    width: 100%;
    object-fit: cover;
}

.icon {
    width: 100px;
    height: auto;
}

.btn-block {
    border-radius: 5px;
    height: 50px;
    font-weight: 500;
    cursor: pointer;
}

fieldset.show {
    display: block;
}