.parallax-container {
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  position: relative;
  width: 100%;
}

.parallax-background {
  height: auto;
  filter: brightness(50%);
  min-width: 100%;
}

.parallax-background, .parallax-placeholder {
  filter: brightness(50%);
}

.parallax-placeholder {
  position: absolute;
  z-index: -200;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  border: 5em;
}

.parallax-content {
  min-height: 70vh;
  color: white;
  padding: 2em 1rem;
}

.parallax-content p {
  font-size: 1.25em;
  text-align: center;
}

.parallax-content .btn {
  width: 100px;
  /*margin: auto;*/
}

@media (min-width: 576px) {
  .parallax-content h1 {
    font-family: arial black;
    font-size: 50px;
    color: white;
    margin: 0px;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
  }
}

