.signup-header{
    background: url('../../assets/img/signUp.jpg'); 
    height: 80vh !important;
    color: #fff;
    background-size: cover; 
    background-position: center;
    background-blend-mode: overlay;
    background-color:#000000a9;

}

.signup-header h1{
    font-size: 56px;
    color:white;
}