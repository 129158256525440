.container{
    max-width: 1570px;
}
.selector-for-some-widget {
  box-sizing: content-box;
}
a:hover { text-decoration: none }

button:focus {
	outline: 0;
	outline: 5px auto -webkit-focus-ring-color;
}

.goog-te-banner-frame.skiptranslate {
    display: none !important;
    }
body {
    top: 0px !important;
    }
/* Header bar section start */

.header-area {
	background: #000000;
	height: 113px;
	display: flex;
	align-items: center;
}
.header-main-menu ul li {
	display: inline-block;
	list-style: none;
	padding-right: 82px;
}
.header-right-menu-blk ul li.active a {
	color: #fff;
  font-size: 22px;
	text-decoration: underline;
}
.header-main-menu ul li a {
	font-size: 20px;
	color: #F2DE96;
	font-weight: 600;
	text-transform: capitalize;
	display: block;
	padding: 20px 0;
}
.header-right-menu-blk ul li a {
	font-size: 20px;
	color: #F2DE96;
	font-weight: 600;
	text-transform: capitalize;
}
.header-right-menu-blk ul li {
	display: inline-block;
	margin-left: 48px;
}
.header-site-right {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.header-right-menu-blk ul {
	list-style: none;
	padding: 0;
}
.header-right-menu-blk ul {
	list-style: none;
	margin: 0;
}
.header-logo {
	margin-left: 92px;
}
.header-menu ul {
  padding: 0;
  margin: 0;
  list-style: none;
}


.header-fl {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-area {
  height: 140px;
  display: flex;
  align-items: center;
}
.header-left {
  display: flex;
  align-items: center;
}

.header-menu ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.header-menu li {
  display: inline-block;
  margin: 0 5px;
}
.header-menu li a {
  color: #87868E;
  font-size: 20px;
  font-weight: 600;
  padding: 3px 30px;
  display: inline-block;
}
.header-right {
  display: flex;
  align-items: center;
}
.header-logo {
  margin-left: 70px;
}
.header-menuu {
  display: flex;
  align-items: center;
}
.header-menuu a {
  color: #87868E;
  font-size: 20px;
  font-weight: 600;
  margin: 0 5px;
  padding: 3px 20px;
}
/* Header bar section  END */

.dropdown-toggle {
  background: transparent;
  border: 0;
  font-size: 25px;
}


/* Hero area css Start */
#myVideo {
	width: 100%;
	border-radius: 30px;
}

.bd-video {
  position: relative;
  height: 970px;
}

.bg-videp {
  height: 100%;
}
#myVideo {
  height: 100%;
  object-fit: cover;
}
.container.ppd {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
}
.hero-containt a {
  margin-bottom: 14px;
  display: block;
}
.hero-containt h3 {
  font-size: 94px;
  color: #FFFFFF;
  font-weight: 700;
  text-shadow: 3px 6px 12px #000;
  margin: 0;
}
.hero-containt p {
  margin: 0;
  font-size: 52px;
  color: #fff;
  font-weight: 700;
  text-shadow: 3px 6px 12px #000;
}
/* Hero area css End */



/* Choose area css Start */
.choose-area {

	padding: 80px 0;
}
.choose-content {

	text-align: center;
	padding: 11px 67px;
  height: auto;
  padding-bottom: 3rem;
}

.section-title h2 {
  font-size: 88px;
	color: #000000;
	font-family: 'Segoe UI';
	margin: 0;
	font-weight: bold;
}

.section-title h3 {
  font-size: 70px;
  color: #4E4E65;
  font-weight: 600;
  margin: 0;
  line-height: 49px;
}

.choose-content h3 {
	font-size: 53px;
	color: #37C8CF;
	margin: 0;
	font-weight: bold;
}
.choose-content p {
	font-size: 25px;
	color: #707070;
	font-weight: normal;
	line-height: 30px;
	margin-bottom: 20px;

}
.choose-btn a {
	background: #37C8CF;
	width: 180px;
	height: 52px;
	display: inline-block;
	text-align: center;
	line-height: 51px;
	border-radius: 30px;
	font-size: 20px;
	color: #F8F4EB;
	transition: .3s;
	border: 1px solid transparent;


}

.offcanva {
	display: none;
}
.choose-btn a:hover {
	background: transparent;
	border: 2px solid #37C8CF;
	color: #37C8CF;
}
.choose-btn a img {
	margin: 0 11px;
}
.choose-blk {
	box-shadow: 0px 4px 12px 8px #E9E9E9;
}
.choose-thumb img {
	width: 100%;
}
.choose-btn {
	margin-bottom: 16px;
}
.choose-content h4 {
	font-size: 34px;
	color: #000000;
	font-weight: 600;
	margin-bottom: 14px;
}
.color-fr {
	color: #FF80B1 !important;
}
.choose-btn.color-btn a {
	background: #FF80B1;
}
/* Choose area css End */



/* Why area css Start */
.norm {
    background-blend-mode: overlay;
    background: rgba(0,0,0,.8);
    background-image: url(../../assets/img/hdImg6.jpg);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    color: #fff;
    padding: 80px 0;
}

.norm .why-area h2{
  color: white;
}

.why-area {
	padding-bottom: 85px;
	padding-top: 30px;
}
.why-area .container {
	max-width: 1190px;
}
.why-area .section-title {
	margin-bottom: 91px;
}
.why-content h3 {
	font-size: 47px;
	color: #FFC600;
	font-weight: bold;
	margin: 0;
}
.why-content h4 {
	font-size: 31px;
	color: #000000;
	font-weight: 600;
	line-height: 41px;
	margin-bottom: 8px;
}
.why-content p {
	font-size: 20px;
	color: #000000;
	font-weight: 400;
	line-height: 27px;
	margin: 0;
}
.why-inner {
	height: 70px;
}
.color-tr {
	color: #37C8CF !important;

}
.color-disd {
	color: #FF80B1 !important;

}
/* Whay area css End */



/* Slider area css Start */
.sider-area {
	background: #000000cf;
	padding: 114px 0;
	padding-left: 50px;
	padding-right: 50px;
}

.sider-area .section-title h2{
	color: white;
  font-size: 88px;
}

.slider-content h4 {
	font-size: 64px;
	color: #fff;
	font-weight: 400;
	line-height: 84px;
	margin-bottom: 13px;
}

.slider-content h5 {
	font-size: 32px;
	color: #fff;
	font-weight: 400;
	margin-bottom: 13px;
}

.slider-content p {
	font-size: 22px;
	color: #fff;
	font-weight: 400;
	line-height: 30px;
	margin: 0;
	padding-right: 28px;
}

.slider-active .owl-dots button {
	width: 33px;
	height: 6px;
	content: "";
	margin: 0 5px;
	border: 1px solid#37C8CF;
  transition: .3s;
}

.slider-active .owl-dot.active {
	background: #37C8CF !important;
}

.slider-active .owl-dots {
	text-align: center;
	max-width: 1008px;
}

.slider-active .owl-nav button {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: -54px;
	font-size: 60px !important;
	color: #8E638B !important;
}
.slider-active .owl-nav button.owl-next {
	left: auto;
	right: -60px;
}
/* Slider area css End */

/* Num area css start*/
.numbers {
    background-blend-mode: overlay;
    background: rgba(0,0,0,.8);
    background-image: url(../../assets/img/achiv.png);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    color: #fff;
    padding: 80px 0;
}

.num{
	color: white;
}

/* serv-us area start*/
.serv-us {
    background: #ffff;
    /* background-image: url(/img/e90aafe…/1.jpg); */
    background-size: cover;
    background-attachment: fixed;
    background-position: 50% 0% !important;
    background-blend-mode: overlay;
    color: #eee;
    padding: 60px 0;
}

.student-title {
    text-align: center;
    margin-bottom: 25px;
}
.student-title h3 {
    margin: 0;
    font-size: 92px;
    color: white;
    line-height: 122px;
    font-weight: 700;
}

.serv-us .our-video {
    position: relative;
    box-shadow: 0px 0px 10px #333;
    border-radius: 4px;
}

.v-middle {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
}

.text-center {
    text-align: center!important;
}
/* serv-us area end*/

/* hero area start*/
.hero {
    background-blend-mode: overlay;
    background: rgb(0 0 0 / 67%);
    background-image: url(../../assets/img/hdImg8.jpg);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    color: #fff;
    padding: 80px 0;
}

.hero {
    padding: 40px;
}

.hero-item{
	padding: 40px;
	margin: auto;
}

.clear-fix {
    clear: both !important;
}

.meet:hover{
	border: 2px solid #ffc600;
}

.learn:hover{
	border: 2px solid #37c8cf;
}

.discover:hover{
	border: 2px solid #ff80b1;
}


.learn
/* hero area end*/

/* Testimonials start */
.t-margin {
	margin-top: 0em;
	font-weight: 500;
}

/* Testimonials end */

.adjust-img-size {
	width: 50%;
	height: auto;
}

p {
    line-height: 1.5;
    margin: 12px 0;
    margin-top: 12pt;
    font-size: 22px;
    font-family: Montserrat, sans-serif;
}

.btn:hover {
    color: #212529;
    text-decoration: none;
    background: #65c6ce;
}


.react-parallax-background-children{
	width: 100%;
}