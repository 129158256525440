.howitworks-header{
    background: url('../../assets/img/faq2_1.jpg');
    height: 80vh !important;
    color: #fff;
    background-size: cover; 
    background-position: center;
    background-blend-mode: overlay;
    background-color:#000000a9;
}

.host-terms{
    height: 80vh !important;
    color: #fff;
    background: url('../../assets/img/hdImg8.jpg');
    background-position: center;
    background-size: cover;
}

.faq-header{
    height: 80vh !important;
    color: #fff;
    background: url('../../assets/img/faq2_1.jpg');
    background-position: center;
    background-size: cover; 
    background-blend-mode: overlay;
    background-color:#000000a9;
}

.site-heading {
    padding-top: 25vh;
}

.privacy-header{
    height: 80vh !important;
    background-position: center;
    color: #fff;
    background-image: url('../../assets/img/hdImg.jpg');
    background-size: cover;
}
