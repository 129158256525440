.aboutus {
    background-blend-mode: overlay;
    background: rgba(0,0,0,.68);
    background-image: url(../../assets/img/eziIntro.png);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    /*color: #fff;*/
    padding: 80px 0;
}

.header {
    min-height: 100vh;
    height: auto;
    width: 100%;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
}

.header-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(34,34,34,.4);
}

.caption {
    margin-top: 30px;
    /*color: #fff;*/
    letter-spacing: 4px;
    text-transform: uppercase;
}

.v-middle {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
}

.caption h1 {
    margin: 50px auto;
    letter-spacing: 10px;
}

.aboutus .vid {
    display: inline-block;
}

article > h1, p {
    display: block;
    margin-top: 16px;
    padding-bottom: 16pt;
}

.features-boxed {
    color: #313437;
    background-color: #eef4f7;
}

.features-boxed .intro {
    font-size: 16px;
    max-width: 500px;
    margin: 0 auto;
}

.features-boxed .features {
    padding: 50px 0;
}

.features-boxed .item {
    text-align: center;
}

.features-boxed .item .box {
    text-align: center;
    padding: 30px;
    background-color: #fff;
    margin-bottom: 30px;
}

.box {
    border: 1px solid rgba(255,255,255,.08);
    background: rgba(255,255,255,.05);
    padding: 80px 20px;
    overflow: hidden;
}

.team-boxed {
    color: #313437;
    background-color: #eef4f7;
}

.team-boxed .intro {
    font-size: 16px;
    max-width: 500px;
    margin: 0 auto;
}

.team-boxed .people {
    padding: 50px 0;
}

.team-boxed .item {
    text-align: center;
}

.team-boxed .item .box {
    text-align: center;
    padding: 30px;
    background-color: #fff;
    margin-bottom: 30px;
}

.team-boxed .item .name {
    font-weight: bold;
    margin-top: 28px;
    margin-bottom: 8px;
    color: inherit;
}

.team-boxed .item .title {
    text-transform: uppercase;
    font-weight: bold;
    color: #d0d0d0;
    letter-spacing: 2px;
    font-size: 13px;
}