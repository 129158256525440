/* header area starts */
.internship {
    background-blend-mode: overlay;
    background: rgba(0,0,0,.68);
    background-image: url(../../assets/img/onint.png);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    color: #fff;
    padding: 80px 0;
}

.header {
    min-height: 100vh;
    height: auto;
    width: 100%;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
}

.header-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(34,34,34,.4);
}

.caption {
    margin-top: 30px;
    color: #fff;
    letter-spacing: 4px;
    text-transform: uppercase;
}

.v-middle {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
}

.caption h1 {
    margin: 50px auto;
    letter-spacing: 10px;
}

.internship .vid {
    display: inline-block;
}

/* header area end */

/* work-area-start */

.work-area {
    background: #F9FAFF 0% 0% no-repeat padding-box;
    padding-bottom: 100px;
  }
  .section-title {
    text-align: center;
    margin-bottom: 49px;
  }
  .section-title h3 {
    font-size: 70px;
    color: #4E4E65;
    font-weight: 600;
    margin: 0;
    line-height: 49px;
  }
  .work-all-fl {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 100px;
  }
  .single-work-box {
      width: 385px;
      height: 456px;
      background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px var(--unnamed-color-fde16f);
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #FDE16F;
      padding: 45px 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      transition: .3s;
}


  .work-img {
    text-align: center;
    height: 175px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .work-text h3 {
    letter-spacing: 0.38px;
    color: #FDE16F;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 7px;
    line-height: 39px;
  }
  .work-text {
    text-align: center;
  }
  .work-text p {
    font-size: 20px;
    line-height: 35px;
    color: #58595B;
    font-weight: 400;
    margin: 0;
  }
  
  .work-btn a {
    color: #FDE16F;
    font-size: 20px;
    font-weight: 35px;
    display: flex;
    align-items: center;
  }

  .work-btn a i {
    margin-left: 10px;
  }

  .single-work-box.sec {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px var(--unnamed-color-7ce3e8);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #7CE3E8;
  }
  .single-work-box.sec h3 {
    color: #7CE3E8;
  }
  .single-work-box.sec a {
    color: #7CE3E8;
  }
  .single-work-box.thard {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px var(--unnamed-color-fdaed1);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #FDAED1;
  }
  .single-work-box.thard h3 {
    color: #FDAED1;
  }
  .single-work-box.thard a {
    color: #FDAED1;
  }

  .single-work-box.clr-1 {
    padding-top: 15px;
    padding-bottom: 26px;
    box-shadow: 0px 3px 6px var(--unnamed-color-fde16f);
    border: 1px solid var(--unnamed-color-fde16f);
    background: #FFF6D8 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #fde16f;
    border: 1px solid #FDE16F;
    opacity: 1;
}

.single-work-box.clr-2 {
    padding-top: 15px;
    padding-bottom: 26px;
    box-shadow: 0px 3px 6px var(--unnamed-color-7ce3e8);
    border: 1px solid var(--unnamed-color-7ce3e8);
    background: #E7F5FE 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #7ce3e8;
    border: 1px solid #7CE3E8;
    opacity: 1;
}

.single-work-box.thard.clr-3 {
    padding-top: 15px;
    padding-bottom: 26px;
    box-shadow: 0px 3px 6px var(--unnamed-color-fdaed1);
    border: 1px solid var(--unnamed-color-fdaed1);
    background: #FFE4F0 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #fdaed1;
    border: 1px solid #FDAED1;
    opacity: 1;
}


.single-work-box:hover {
	background: #FDE16F;
}
.single-work-box.sec:hover {
	background: #7CE3E8;
}
.single-work-box.thard:hover {
	background: #FDAED1;
}
.single-work-box:hover .work-text h3 {
	color: #fff;
}
.single-work-box:hover .work-btn a {
	color: #fff;
}
  /* work-area end */
  
  /* program-area start */
.program-aera {
    padding: 40px 0;
}

.section-title {
    text-align: center;
    margin-bottom: 49px;
}

.section-title h3 {
    font-size: 50px;
    line-height: 65px;
    color: #4E4E65;
    font-weight: bold;
    margin-bottom: 37px;
}

.program-fx-5 {
    width: 1009px;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    justify-content: space-between;
}

.single-program {
    max-width: 525px;
    background: #E7F5FE;
    border-radius: 10px;
    margin-bottom: 50px;
    transition: .3s;
}

.single-box-fx {
    display: flex;
    align-items: center;
    padding: 30px;
    max-width: 478px;
    transition: .3s;
}

.program-icon {
    width: 172px;
    background: #34C5CC;
    height: 116px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 27px;
}

.program-icon img {
    max-width: 83px;
}

.single-program.pgr-2 {
    background: #FFF6D8;
}

.program-icon.icn-2 {
    background: #FBDA57;
}

.single-program.pgr-3 {
	background: #FFE4F0;
}

.program-icon.icn-3 {
	background: #F595BA;
}

.single-program.pgr-1:hover {
	background: #34C5CC;
}
.single-program.pgr-1:hover .program-content p {
	color: #fff;
}
.single-program.pgr-2:hover {
	background: #FBDA57;
}
.single-program.pgr-3:hover {
	background: #F595BA;
}
.single-program.pgr-4:hover {
	background: #34C5CC;
}

/* program area end */

/* choose area start*/
.mb-50 {
    margin-bottom: 50px !important;
}

.section-title h2 {
    font-size: 88px;
    color: #000000;
    font-family: 'Segoe UI';
    margin: 0;
    font-weight: bold;
}

.choose-blk {
    box-shadow: 0px 4px 12px 8px #e9e9e9;
}

.choose-thumb img {
    width: 100%;
}

.choose-content {
    text-align: center;
    padding: 11px 67px;
    height: auto;
    padding-bottom: 3rem;
}

.choose-content h3 {
    font-size: 53px;
    color: #37C8CF;
    margin: 0;
    font-weight: bold;
}

.choose-content h4 {
    font-size: 34px;
    color: #000000;
    font-weight: 600;
    margin-bottom: 14px;
}

.choose-content p {
    font-size: 22px;
    color: #707070;
    font-weight: normal;
    line-height: 30px;
    margin-bottom: 20px;
}

.choose-btn {
    margin-bottom: 16px;
}

.choose-btn a {
    background: #37C8CF;
    width: 180px;
    height: 52px;
    display: inline-block;
    text-align: center;
    line-height: 51px;
    border-radius: 30px;
    font-size: 18px;
    color: #F8F4EB;
    transition: .3s;
    border: 1px solid transparent;
}

.choose-btn a:hover{
    background: white;
}

.color-fr {
    color: #FF80B1 !important;
}
/* choose area end */

/* journey area start */
.journey {
    padding: 100px 0;
}

.journey-wrap {
    text-align: center;
}
/* journey area end */

/* benefit area start */
.benifit-fx {
    display: flex;
    justify-content: space-between;
}

.single-benifit.hov-1 {
    transition: .3s;
}

.single-benifit {
    width: 18%;
    text-align: center;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    border: 3px solid var(--unnamed-color-ffffff);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 3px 6px 20px #ffc60070;
    border: 3px solid #FFFFFF;
    border-radius: 12px;
    opacity: 1;
    padding: 40px 0;
}

.benifiut-text p {
	font-size: 24px;
	color: #4E4E65;
	line-height: 35px;
}

.single-benifit.ben-1 {
	background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
	border: 3px solid var(--unnamed-color-ffffff);
	background: #FFFFFF 0% 0% no-repeat padding-box;
	box-shadow: 3px 6px 20px #7CE3E88C;
	border: 3px solid #FFFFFF;
	border-radius: 12px;
	opacity: 1;
}

.single-benifit.ben-2 {
	background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
	border: 3px solid var(--unnamed-color-ffffff);
	background: #FFFFFF 0% 0% no-repeat padding-box;
	box-shadow: 3px 6px 20px #F595BA77;
	border: 3px solid #FFFFFF;
	border-radius: 12px;
	opacity: 1;
}

.single-benifit.ben-3 {
	background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
	border: 3px solid var(--unnamed-color-ffffff);
	background: #FFFFFF 0% 0% no-repeat padding-box;
	box-shadow: 3px 6px 20px #7CE3E88C;
	border: 3px solid #FFFFFF;
	border-radius: 12px;
	opacity: 1;
}

.benifit-area {
	background: #D5F2F8;
	padding-top: 200px;
	padding-bottom: 250px;
}

.single-benifit.hov-1 {
	transition: .3s;
}
.single-benifit.hov-1:hover {
	background: #FFC600;
}
.single-benifit.ben-1.hov-2:hover {
	background: #37C8CF;
}

.single-benifit.ben-3.hov-2:hover {
	background: #37C8CF;
}
.single-benifit.ben-2.hov-3:hover {
	background: #F595BA;
}
.single-benifit.ben-2.hov-3{
  transition: .3s;
}
.single-benifit.ben-1.hov-2 {
	transition: .3s;
}

/* benefit area end */

.btn:not(:disabled):not(.disabled) {
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 32px;
    background: #f8c828;
    border-style: none;
    padding-right: 25px;
    padding-left: 25px;
    margin-top: 0px;
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}
