.post-heading, header.masthead .site-heading {
    padding: 200px 0 150px;
    color: #fff;
}


.site-heading {
    padding-top: 25vh;
}

.site-heading h1 {
    font-size: 69px !important;
    color: white !important;
    font-weight: 800 !important;
}

@media (min-width: 1px) and (max-width: 800px){
    .site-heading h1 {
        font-size: 36px !important;
    }
}

.post-preview{
    text-align: left;
}

.post-preview h2{
    font-size: 59px;
}


article{
    text-align: left;
}

.blog_home{
    background-blend-mode: overlay !important;
    background: rgba(0,0,0,0.4);
    height: 80vh !important;
    background-size: cover !important;
    background-position: center;
    background-attachment: fixed;
    color: #fff;
    background-image: url("../../assets/img/home-bg.jpg");
}

.post_1{
    background-blend-mode: overlay !important;
    background: rgba(0,0,0,0.4);
    height: 80vh !important;
    background-size: cover !important;
    background-position: center;
    background-attachment: fixed;
    color: #fff;
    background: url('../../assets/img/blogBg_2.jpg');
}

.post_2{
    background-blend-mode: overlay !important;
    background: rgba(0,0,0,0.4);
    height: 80vh !important;
    background-size: cover !important;
    background-position: center;
    background-attachment: fixed;
    color: #fff;
    background: url('../../assets/img/blogBg_2.jpg');
}

.post_3{
    background-blend-mode: overlay !important;
    background: rgba(0,0,0,0.4);
    height: 80vh !important;
    background-size: cover !important;
    background-position: center;
    background-attachment: fixed;
    color: #fff;
    background: url('../../assets/img/blogImg3_1.jpg') bottom / cover;
}

.post_4{
    background-blend-mode: overlay !important;
    background: rgba(0,0,0,0.4);
    height: 80vh !important;
    background-size: cover !important;
    background-position: center;
    background-attachment: fixed;
    color: #fff;
    background: url('../../assets/img/blogImg4_1.jpg');
}

.post_5{
    background-blend-mode: overlay !important;
    background: rgba(0,0,0,0.4);
    height: 80vh !important;
    background-size: cover !important;
    background-position: center;
    background-attachment: fixed;
    color: #fff;
    background: url('../../assets/img/blog5hdr.png');
}

.post_6{
    background-blend-mode: overlay !important;
    background: rgba(0,0,0,0.4);
    height: 80vh !important;
    background-size: cover !important;
    background-position: center;
    background-attachment: fixed;
    color: #fff;
    background: url('../../assets/img/blogImg_5.jpg') no-repeat;
    background-size: cover;
}

.post_7{
    background-blend-mode: overlay !important;
    background: rgba(0,0,0,0.4);
    height: 80vh !important;
    background-size: cover !important;
    background-position: center;
    background-attachment: fixed;
    color: #fff;
    background: url('../../assets/img/blog7_0.png') no-repeat;
    background-size: cover;
}

.post_8{
    background-blend-mode: overlay !important;
    background: rgba(0,0,0,0.4);
    height: 80vh !important;
    background-size: cover !important;
    background-position: center;
    background-attachment: fixed;
    color: #fff;
    background: url('../../assets/img/blog8_0.png');
    background-size: cover;
}

.post_9{
    background-blend-mode: overlay !important;
    background: rgba(0,0,0,0.4);
    height: 80vh !important;
    background-size: cover !important;
    background-position: center;
    background-attachment: fixed;
    color: #fff;
    background: url('../../assets/img/blog8_2.png');
    background-size: cover;
}

.post_10{
    background-blend-mode: overlay !important;
    background: rgba(0,0,0,0.4);
    height: 80vh !important;
    background-size: cover !important;
    background-position: center;
    background-attachment: fixed;
    color: #fff;
    background: url('../../assets/img/blogBg_2.jpg');
    background-size: cover;
}



