.masthead {
    background-blend-mode: overlay !important;
    background: rgba(0,0,0,0.4);
    height: 80vh !important;
    background-size: cover !important;
    background-position: center;
    background-attachment: fixed;
    color: #fff;
}

.Affiliates{
    background: url('../../assets/img/hdImg7.jpg');
    background-size: cover; 
    height:80vh
}

#signUpform{
    text-align: left;
    border-radius: 10px;
    font-family: Montserrat, sans-serif;
    margin-bottom: 10rem;
}

.form-check-label{
    font-family: 'Open Sans', sans-serif;
}