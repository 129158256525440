#nav-item{
    color: black;
}

#nav-dropdown{
    font-size: 20px;
    color: white;
}

#join-nav-dropdown{
    color: white;
    font-size: 20px;
    border-radius:10rem; 
    border:2px solid #ff80b1; 
    padding-left:1rem;
}

#icon-nav-dropdown{
    color: white;
    font-size: 20px;
    padding-right:4rem;
}

.dropdown-toggle::after {
    display:none;
}
   