.masthead {
    background-blend-mode: overlay !important;
    background: rgba(0,0,0,0.4);
    height: 80vh !important;
    background-size: cover !important;
    background-position: center;
    background-attachment: fixed;
    color: #fff;
}

.site-heading {
    padding-top: 25vh;
}

.invite-header{
    background: url('../../assets/img/invitefriend.jpg'); 
    background-position: center;
    height: 80vh !important;
    background-size:cover; 
    height:80vh;
}

.highlight-phone{
    background: url('../../assets/img/refbg2.svg') no-repeat;
    background-size: cover;
    padding-top: 64px;
}