@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:wght@300;400;500;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
* {
  -o-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  text-decoration: none !important;
  list-style: none !important;
  outline: none !important;
}

.alignleft {
    float: left;
    margin-right: 15px;
}
.alignright {
    float: right;
    margin-left: 15px;
}
.aligncenter {
    display: block;
    margin: 0 auto 15px;
}
a:focus { outline: 0 solid }
*{box-sizing: border-box;}
img {
    max-width: 100%;
    height: auto;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 15px;
  color: #1B1B21;
font-family: 'Montserrat', sans-serif !important;
}
body {
  color: #545051;
  font-weight: 300;
  font-family: 'Montserrat', sans-serif !important;
}

@font-face {
    font-family:RusticLoveTattoo;
    src: url(/static/media/RusticLoveTattoo.d337e272.otf);
}

ul{
    list-style: none;
    margin: 0;
    padding: 0;
}
ol{
  list-style-type: none;
}


@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

p {
  line-height: 1.5;
  margin: 12px 0;
  margin-top: 12pt;
  font-size: 22px;
  font-family: Montserrat, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0 0 15px;
  color: #1B1B21;
  font-family: 'Montserrat', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400 !important;
}

* {
  -o-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  text-decoration: none !important;
  list-style: none !important;
  outline: none !important;
}

img {
  max-width: 100%;
  height: auto;
}

.fb_reset {
  background: none;
  border: 0;
  border-spacing: 0;
  color: #000;
  cursor: auto;
  direction: ltr;
  font-family: "lucida grande", tahoma, verdana, arial, sans-serif;
  font-size: 11px;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1;
  margin: 0;
  overflow: visible;
  padding: 0;
  text-align: left;
  text-decoration: none;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
}

#nav-item{
    color: black;
}

#nav-dropdown{
    font-size: 20px;
    color: white;
}

#join-nav-dropdown{
    color: white;
    font-size: 20px;
    border-radius:10rem; 
    border:2px solid #ff80b1; 
    padding-left:1rem;
}

#icon-nav-dropdown{
    color: white;
    font-size: 20px;
    padding-right:4rem;
}

.dropdown-toggle::after {
    display:none;
}
   
@media (min-width: 1449px) {
	.video-wrapper{
		height: 600px;
	}
}

/* XL Device :1200px. */

@media (min-width: 1200px) and (max-width: 1449px) {
	.single-what {
		width: 390px !important;
		margin-bottom: 30px !important;
	}
	.what-text h3 {
        font-size: 20px;
        margin-bottom: 7px;
    }
    .what-text p {
        font-size: 15px !important;
        line-height: 28px !important;
    }
	.about-box-2 {
        width: 297px;
        padding-left: 24px;
        border-radius: 30px;
    }
    .about-box-title h4 {
        font-size: 22px;
        color: #fff;
        font-weight: 600;
        margin-bottom: 21px;
    }
    .about-wrap {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
            -ms-flex-align: center;
                align-items: center;
        position: relative;
        margin-bottom: 40px;
    }
	.video-wrapper{
		height: 500px;
	}
	.header-main-menu ul li a {
		font-size: 18px;
	}
	.hero-text {
		padding-top: 20%;
	}
	.hero-text span {
		font-size: 60px;
		line-height: 3rem;
		padding-right: 15%;
	}
	.header-main-menu ul li {
		padding-right: 65px;
	}
	.header-right-menu-blk ul li a {
		font-size: 18px;
	}
	.header-right-menu-blk ul li {
		margin-left: 38px;
	}
	.header-logo {
		margin-left: 40px;
	}
	.section-title h2 {
		font-size: 64px;
		margin-top: 1.5em;
	}

	.norm .why-area h2 {
		font-size: 64px;
		margin-top: 1.5em;
	}

	.choose-content h3 {
		font-size: 32px !important;
	}
	.choose-content h4 {
		font-size: 24px !important;
		margin-bottom: 1.5em !important;
	}
	.choose-content p {
		font-size: 18px !important;
		line-height: 26px !important;
		margin-bottom: 2em !important;
	}
	.why-area .section-title {
		margin-bottom: 10px;
	}
	.why-thumb img {
		height: auto;
	}
	.why-content h3 {
		font-size: 38px;
	}
	.why-content h4 {
		font-size: 26px;
		line-height: 31px;
		margin-bottom: 8px;
	}
	.why-inner {
		height: 46px;
	}
	.color-disd {
		color: #FF80B1 !important;
		margin-bottom: 4px !important;
	}
	.choose-area {
		padding: 31px 0;
	}
	.single-slider-item .slider-content h4 {
		font-size: 40px;
		line-height: 58px;
		margin-bottom: 13px;
	}
	.single-slider-item .slider-content h5 {
		font-size: 28px;
		margin-bottom: 13px;
	}
	.single-slider-item .slider-content p {
		font-size: 20px;
		line-height: 26px;
		padding-right: 0;
	}
	.sider-area {
		padding: 42px 0;
	}
	.single-far-brand {
		width: 186px;
		height: auto;
		border-radius: 40px;
		padding: 41px 10px;
		text-align: center;
		position: relative;
	}
	.single-far-brand::before {
		width: 241px;
	}
	.for-brand-fl {
		max-width: 1100px;
	}
	.far-top-box p {
		font-size: 31px;
		line-height: 33px;
	}
	.far-area {
		padding-bottom: 90px;
		padding-top: 45px;
	}
	.student-wrap {
		width: 100%;
		padding: 15px;
		padding-bottom: 40px;
	}
	.student-content {
		width: 575px;
	}
	.student-content h3 {
		font-size: 36px;
		margin-bottom: 16px;
	}
	.student-wrap a {
		top: 82%;
	}
	.student-content p {
		font-size: 23px;
		line-height: 31px;
	}
	.student-aera {
		padding-top: 70px;
		padding-bottom: 70px;
	}
	.section-title h3 {
		font-size: 40px;
		margin-top: 2em;
		margin-bottom: 18px;
	}
	.Engage-title h3 {
		font-size: 40px;
		margin-bottom: 60px;
	}
	.Engage-blk img {
		margin-bottom: 36px;
		height: auto;
		width: 200px;
	}
	.choose-btn.choose-bty a {
		width: 230px;
		font-size: 20px;
	}
	.footer-list ul li a {
		font-size: 17px;
		line-height: 20px;
		margin-bottom: 20px;
	}
	.footer-body-text p {
		font-size: 15px;
		line-height: 24px;
	}
	.footer-social ul li a {
		font-size: 22px;
		margin-bottom: 11px;
	}
	.single-input input {
		font-size: 20px;
		margin-bottom: 24px;
	}
	.cheack-area {
		padding: 40px 0;
	}
	.cheack-blk {
		margin-bottom: 4em;
	}
	.section-title p {
		font-size: 20px;
		line-height: 32px;
		margin-bottom: 38px;
	}
	.slider-active .owl-nav button {
		left: 0;
	}
	.slider-active .owl-nav button.owl-next {
		left: auto;
		right: 0;
	}
	.client-fx {
		width: 100%;
	}
	.hero-containt a img {
		max-width: 200px;
	}
	.hero-containt h3 {
		font-size: 70px;
	}
	.hero-containt p {
		font-size: 40px;
	}
	.bd-video {
		height: 700px;
	}
	.feature-area {
        padding: 60px 0;
    }
    .feature-single a {
        width: 170px;
        height: 170px;
        line-height: 170px;
        border-radius: 50px;
        font-size: 72px;
    }
    .feature-text h3 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 9px;
    }
    .feature-text p {
        font-size: 16px;
        line-height: 31px;
        margin-bottom: 0;
    }
	.feature-fl {
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
        padding: 0 20px;
    }
	.single-feature {
        width: 210px;
        text-align: center;
    }

	.feature-text h3 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 9px;
    }
    .feature-text p {
        font-size: 16px;
        line-height: 31px;
        margin-bottom: 0;
    }
	.work-area .work-all-fl {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
                justify-content: space-between;
        padding: 0px 60px;
    }
    .work-area .single-work-box {
        width: 324px;
        height: 456px;
        padding: 45px 40px;
    }
    .work-area {
        padding-bottom: 50px;
    }
}

/* LG Device :992px. */

@media (min-width: 992px) and (max-width: 1200px) {
	.what-area {
        padding: 40px 0 !important;
    }
	.single-what {
		width: 370px !important;
		margin-bottom: 26px !important;
	}
	.what-text h3 {
        font-size: 20px;
        margin-bottom: 7px;
    }
    .what-text p {
        font-size: 15px !important;
        line-height: 28px !important;
    }
	.about-box-2 {
        width: 297px;
        padding-left: 24px;
        border-radius: 30px;
    }
    .about-box-title h4 {
        font-size: 19px;
        color: #fff;
        font-weight: 600;
        margin-bottom: 20px;
    }
    .about-wrap {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
            -ms-flex-align: center;
                align-items: center;
        position: relative;
        margin-bottom: 40px;
    }
	.benifit-area {
		background: #D5F2F8;
		padding-top: 50px;
		padding-bottom: 50px;
	}

	.benifit-area .single-benifit {
		padding: 22px 0;
	}
	.program-aera .program-fx-5 {
		width: 100%;
	}
	.program-aera .single-program {
		max-width: 50%;
		margin-bottom: 50px;
	}

	.video-wrapper{
		height: 420px;
	}

	#join-btn {
		margin-left: -0.25em;
	}
	.header-main-menu ul li a {
		font-size: 16px;
	}
	.hero-text {
		padding-top: 20%;
	}
	.hero-text span {
		font-size: 64px;
		line-height: 3rem;
		padding-right: 15%;
	}
	.header-main-menu ul li {
		padding-right: 40px;
	}
	.header-right-menu-blk ul li a {
		font-size: 16px;
	}
	.client-fx {
		width: 100%;
	}
	.header-right-menu-blk ul li {
		margin-left: 22px;
	}
	.header-logo {
		margin-left: 20px;
	}
	.section-title h2 {
		font-size: 40px;
	}
	.norm .why-area h2 {
		font-size: 40px;
	}
	.choose-content h3 {
		font-size: 28px !important;
		margin-top: 1em !important;
	}
	.choose-content h4 {
		font-size: 20px !important;
		margin-bottom: 1.5em !important;
	}
	.choose-content p {
		font-size: 16px !important;
		line-height: 23px !important;
		margin-bottom: 2em !important;
	}
	.why-area .section-title {
		margin-bottom: 10px;
	}
	.why-thumb img {
		height: auto;
	}
	.why-content h3 {
		font-size: 30px;
	}
	.why-content h4 {
		font-size: 21px;
		line-height: 31px;
		margin-bottom: 4px;
	}
	.why-inner {
		height: 62px;
	}
	.color-disd {
		color: #FF80B1 !important;
		margin-bottom: 4px !important;
	}
	.choose-area {
		padding: 31px 0;
	}
	.single-slider-item .slider-content h4 {
		font-size: 38px;
		line-height: 50px;
		margin-bottom: 13px;
	}
	.single-slider-item .slider-content h5 {
		font-size: 24px;
		margin-bottom: 13px;
	}
	.single-slider-item .slider-content p {
		font-size: 20px;
		line-height: 26px;
		padding-right: 0;
	}
	.sider-area {
		padding: 42px 0;
	}
	.single-far-brand {
		width: 30%;
		height: auto;
		border-radius: 40px;
		padding: 41px 10px;
		text-align: center;
		position: relative;
		margin: 0 16px;
		margin-bottom: 95px;
	}
	.single-far-brand::before {
		width: 400px;
	}
	.for-brand-fl {
		max-width: 100%;
	}
	.far-top-box p {
		font-size: 28px;
		line-height: 33px;
	}
	.far-area {
		padding-bottom: 0;
		padding-top: 45px;
	}
	.student-wrap {
		width: 100%;
		padding: 15px;
		padding-bottom: 40px;
	}
	.student-content {
		width: 575px;
	}
	.student-content h3 {
		font-size: 36px;
		margin-bottom: 16px;
	}
	.student-wrap a {
		top: 82%;
	}
	.student-content p {
		font-size: 18px;
		line-height: 25px;
	}
	.student-aera {
		padding-top: 30px;
		padding-bottom: 45px;
	}
	.section-title h3 {
		font-size: 40px;
		margin-bottom: 12px;
	}
	.Engage-title h3 {
		font-size: 40px;
		margin-bottom: 40px;
	}
	.Engage-blk img {
		margin-bottom: 36px;
		height: auto;
		width: 200px;
	}
	.choose-btn.choose-bty a {
		width: 189px;
		font-size: 17px;
	}
	.footer-list ul li a {
		font-size: 17px;
		line-height: 20px;
		margin-bottom: 20px;
	}
	.footer-body-text p {
		font-size: 15px;
		line-height: 24px;
	}
	.footer-social ul li a {
		font-size: 22px;
		margin-bottom: 11px;
	}
	.single-input input {
		font-size: 20px;
		margin-bottom: 24px;
	}
	.cheack-area {
		padding: 40px 0;
	}
	.section-title p {
		font-size: 20px;
		line-height: 30px;
		margin-bottom: 38px;
	}
	.slider-active .owl-nav button {
		left: 0;
	}
	.slider-active .owl-nav button.owl-next {
		left: auto;
		right: 0;
	}
	.choose-btn a {
		width: 289px;
		height: 53px;
		line-height: 47px;
		font-size: 20px;
	}
	.single-slider-item .slider-content h4 br {
		display: none;
	}
	.far-area .section-title {
		margin-bottom: 45px;
	}
	.far-top-box {
		width: 228px;
		height: 170px;
		padding: 0 20px;
	}
	.for-brand-fl {
		max-width: 100%;
		margin: auto;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-justify-content: center;
		    -ms-flex-pack: center;
		        justify-content: center;
		-webkit-flex-wrap: wrap;
		    -ms-flex-wrap: wrap;
		        flex-wrap: wrap;
	}
	.single-far-brand span {
		height: 20px;
		width: 20px;
		left: -34px;
	}
	.single-far-brand span:nth-child(2) {
		right: -33px;
		left: auto;
	}
	.student-title h3 {
		line-height: 80px;
		font-size: 40px;
	}
	.student-hat img {
		height: 80px;
	}
	.footer-widget h4 {
		font-size: 17px;
		margin-bottom: 25px;
	}
	.hero-containt a img {
		max-width: 200px;
	}
	.hero-containt h3 {
		font-size: 60px;
	}
	.hero-containt p {
		font-size: 32px;
	}
	.bd-video {
		height: 600px;
	}
	.feature-area {
        padding: 60px 0;
    }
    .feature-single a {
        width: 120px;
        height: 120px;
        line-height: 120px;
        border-radius: 33px;
        font-size: 55px;
    }
    .feature-text h3 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 9px;
    }
    .feature-text p {
        font-size: 15px;
        line-height: 24px;
        margin-bottom: 0;
    }

	.feature-area .single-feature {
        width: 210px;
        text-align: center;
    }
	.work-area .work-all-fl {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
                justify-content: space-between;
        padding: 0;
    }
    .work-area .single-work-box {
        width: 297px;
        height: 426px;
        padding: 20px 25px;
    }
    .work-area {
        padding-bottom: 50px;
    }
}

/* MD Device :768px. */

@media (min-width: 768px) and (max-width: 991px) {
	.what-area {
		padding: 40px 0 !important;
	}
	.what-fl {
		padding: 0 50px !important;
	}
	.single-what {
		width: 313px !important;
		margin-bottom: 26px !important;
	}
	.what-text h3 {
		font-size: 20px;
		margin-bottom: 7px;
	}
	.what-text p {
		font-size: 15px !important;
		line-height: 28px !important;
	}
	.about-wrap {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
            -ms-flex-align: center;
                align-items: center;
        position: relative;
        margin-bottom: 18px;
    }
	.about-box-2 {
        width: 297px;
        padding-right: 90px;
        border-radius: 30px;
    }
    .about-box-2 h4 {
        font-size: 19px;
        color: #fff;
        font-weight: 600;
        margin-bottom: 20px;
    }
	.about-box-2 p {
		font-size: 14px;
		color: #000000;
		margin-bottom: 21px;
	}

	.about-img {
		position: absolute;
		left: 21%;
		width: 166px;
		top: 50%;
		-webkit-transform: translateY(-50%);
		    -ms-transform: translateY(-50%);
		        transform: translateY(-50%);
	}
	.about-img.image-df {
		position: absolute;
		right: 13%;
		left: auto;
		top: 50%;
		-webkit-transform: translateY(-50%);
		    -ms-transform: translateY(-50%);
		        transform: translateY(-50%);
	}
	.about-wrap .about-text-box {
		width: 770px;
		background: #E7F5FE;
		padding-top: 26px;
		padding-left: 64px;
		padding-bottom: 23px;
		padding-right: 28px;
		border-radius: 24px;
		position: relative;
		z-index: -1;
	}
	.about-wrap .about-text-box h4 {
        font-size: 19px;
        color: #fff;
        font-weight: 600;
        margin-bottom: 20px;
    }
	.about-wrap .about-text-box p {
		font-size: 14px;
		color: #000000;
		margin-bottom: 21px;
	}

	.benifit-area {
		background: #D5F2F8;
		padding-top: 50px;
		padding-bottom: 50px;
	}
	.benifit-area .benifit-fx {
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-justify-content: center;
		    -ms-flex-pack: center;
		        justify-content: center;
		-webkit-flex-wrap: wrap;
		    -ms-flex-wrap: wrap;
		        flex-wrap: wrap;
	}
	.benifit-area .single-benifit {
		padding: 22px 0;
		width: 29%;
		margin: 0 15px;
		margin-bottom: 30px;
	}
	.program-aera .program-fx-5 {
		width: 100%;
	}
	.program-aera .single-program {
		width: 1009px;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		    -ms-flex-wrap: wrap;
		        flex-wrap: wrap;
		-webkit-justify-content: space-between;
		    -ms-flex-pack: justify;
		        justify-content: space-between;
		margin-left: 15%;
	}
	
	.video-wrapper{
		height: 350px;
	}

	#join-btn {
		margin-left: -0.25em;
	}
	.client-fx {
		width: 100%;
	}
	.hero-text {
		padding-top: 10%;
	}
	.hero-text span {
		font-size: 32px;
		line-height: 3rem;
	}
	.navbar-light .navbar-toggler {
		color: #7ce3e8;
		border-color: #ffc107;
		margin-right: 3rem;
	}
	.header-main-menu ul li a {
		font-size: 16px;
	}
	.header-main-menu ul li {
		padding-right: 23px;
	}
	.header-right-menu-blk ul li a {
		font-size: 16px;
	}
	.header-area {
		height: 88px;
	}
	.header-right-menu-blk ul li {
		margin-left: 22px;
	}
	.header-logo {
		margin-left: 2px;
	}
	.section-title h2 {
		font-size: 36px;
	}
	.norm .why-area h2 {
		font-size: 36px;
	}
	.choose-content h3 {
		font-size: 28px !important;
	}
	.choose-content h4 {
		font-size: 20px !important;
		margin-bottom: 11px !important;
	}
	.choose-content p {
		font-size: 16px !important;
		line-height: 23px !important;
		margin-bottom: 20px !important;
		height: 64px !important;
	}
	.why-area .section-title {
		margin-bottom: 10px;
	}
	.why-thumb img {
		height: auto;
	}
	.why-content h3 {
		font-size: 28px;
	}
	.why-content h4 {
		font-size: 18px;
		line-height: 22px;
		margin-bottom: 4px;
	}
	.why-inner {
		height: 62px;
	}
	.color-disd {
		color: #FF80B1 !important;
		margin-bottom: 4px !important;
	}
	.choose-area {
		padding: 31px 0;
	}
	.single-slider-item .slider-content h4 {
		font-size: 28px;
		line-height: 55px;
		margin-bottom: 2px;
	}
	.single-slider-item .slider-content h5 {
		font-size: 24px;
		margin-bottom: 13px;
	}
	.single-slider-item .slider-content p {
		font-size: 20px;
		line-height: 26px;
		padding-right: 0;
	}
	.sider-area {
		padding: 42px 0;
	}
	.single-far-brand {
		width: 28%;
		height: 360px;
		border-radius: 40px;
		padding: 41px 10px;
		margin: 0 16px;
		margin-bottom: 95px;
	}
	.single-far-brand::before {
		width: 251px;
	}
	.for-brand-fl {
		max-width: 100%;
	}
	.far-top-box p {
		font-size: 21px;
		line-height: 29px;
	}
	.far-area {
		padding-bottom: 0;
		padding-top: 45px;
	}
	.student-wrap {
		width: 100%;
		padding: 15px;
		padding-bottom: 40px;
	}
	.student-content {
		width: 575px;
	}
	.student-content h3 {
		font-size: 30px;
		margin-bottom: 16px;
	}
	.student-wrap a {
		top: 82%;
	}
	.student-content p {
		font-size: 18px;
		line-height: 25px;
	}
	.student-aera {
		padding-top: 30px;
		padding-bottom: 45px;
	}
	.section-title h3 {
		font-size: 36px;
		margin-bottom: 12px;
	}
	.Engage-title h3 {
		font-size: 36px;
		margin-bottom: 40px;
	}
	.Engage-blk img {
		height: auto;
		width: 200px;
	}
	.choose-btn.choose-bty a {
		width: 189px;
		font-size: 17px;
	}
	.footer-list ul li a {
		font-size: 17px;
		line-height: 20px;
		margin-bottom: 20px;
	}
	.footer-body-text p {
		font-size: 15px;
		line-height: 24px;
	}
	.footer-social ul li a {
		font-size: 22px;
		margin-bottom: 11px;
	}
	.single-input input {
		font-size: 20px;
		margin-bottom: 24px;
	}
	.cheack-area {
		padding: 40px 0;
	}
	.section-title p {
		font-size: 18px;
		line-height: 30px;
		margin-bottom: 19px;
	}
	.slider-active .owl-nav button {
		left: 0;
	}
	.slider-active .owl-nav button.owl-next {
		left: auto;
		right: 0;
	}
	.choose-btn a {
		width: 220px;
		height: 45px;
		line-height: 39px;
		font-size: 16px;
	}
	.single-slider-item .slider-content h4 br {
		display: none;
	}
	.far-area .section-title {
		margin-bottom: 45px;
	}
	.far-top-box {
		width: 174px;
		height: 170px;
		padding: 0 1px;
	}
	.for-brand-fl {
		max-width: 100%;
		margin: auto;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-justify-content: center;
		    -ms-flex-pack: center;
		        justify-content: center;
		-webkit-flex-wrap: wrap;
		    -ms-flex-wrap: wrap;
		        flex-wrap: wrap;
	}
	.single-far-brand span {
		height: 20px;
		width: 20px;
		left: -34px;
	}
	.single-far-brand span:nth-child(2) {
		right: -33px;
		left: auto;
	}
	.student-title h3 {
		line-height: 68px;
		font-size: 35px;
	}
	.student-hat img {
		height: 80px;
	}
	.section-title {
		margin-bottom: 30px;
	}
	.footer-widget h4 {
		font-size: 17px;
		margin-bottom: 25px;
	}
	.header-logo a img {
		height: 68px;
	}
	.why-content p {
		font-size: 16px;
		line-height: 24px;
		margin: 0;
	}
	.why-area {
		padding-bottom: 35px;
		padding-top: 30px;
	}
	.Engage-area {
		padding-bottom: 30px;
		padding-top: 20px;
	}
	.hero-containt a img {
		max-width: 200px;
	}
	.hero-containt h3 {
		font-size: 52px;
	}
	.hero-containt p {
		font-size: 32px;
	}
	.hero-containt a {
		text-align: left;
	}
	.bd-video {
		height: 600px;
	}

	.feature-area {
        padding: 60px 0;
    }

    .feature-single a {
        width: 120px;
        height: 120px;
        line-height: 120px;
        border-radius: 33px;
        font-size: 55px;
    }

    .feature-text h3 {
        font-size: 40px;
        font-weight: 600;
        margin-bottom: 9px;
    }
    .feature-text p {
        font-size: 13px;
        line-height: 24px;
        margin-bottom: 0;
    }
	.single-feature {
        width: 210px;
        text-align: center;
    }
	.work-area .work-all-fl {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
                justify-content: space-between;
        padding: 0;
    }
    .work-area.single-work-box {
        width: 226px;
        height: 380px;
        padding: 13px 19px;
    }
    .work-area {
        padding-bottom: 50px;
    }
}

/* SM Small Device :320px. */

@media only screen and (min-width: 100px) and (max-width: 767px) {
	#hero h3{
		font-size: 20px;
	}
	.slider-content h4{
		font-size: 32px !important;
		line-height: 24px !important;
	}

	.slider-content h5{
		font-size: 20px !important;
	}

	.slider-content p{
		font-size: 15px !important;
		padding-right: 0 !important;

	}

	.team-boxed .item .box {
		width: 100% !important;
	}

	.what-area {
		padding: 40px 0 !important;
	}
	.what-fl {
		padding: 0 !important;
	}
	.feature-area {
        padding: 40px 0 !important;
    }
	.single-what {
		width: 100% !important;
		margin-bottom: 26px !important;
		text-align: center !important;
	}
	.what-text h3 {
		font-size: 20px;
		margin-bottom: 7px;
	}
	.what-text p {
		font-size: 15px !important;
		line-height: 28px !important;
	}
	.feature-fl {
        padding: 0 !important;
		display: block !important;
		-webkit-flex-direction: column;
		    -ms-flex-direction: column;
		        flex-direction: column;
    }
	
    .single-feature {
        width: 100% !important;
        text-align: center !important;
        margin-bottom: 30px;
    }
	
    .single-feature .feature-text h3 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
    }
    .single-feature .feature-text p {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 0;
    }

	.about-wrap {
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-direction: column;
		    -ms-flex-direction: column;
		        flex-direction: column;
        -webkit-align-items: center;
            -ms-flex-align: center;
                align-items: center;
        position: relative;
        margin-bottom: 26px;
    }
	
	.about-wrap .about-box-2 {
        width: 100%;
		margin-right: 0;
		padding-left: 30px;
        border-radius: 30px;
        margin-bottom: 30px;
		text-align: left;
    }

	.about-wrap .about-img {
		position: absolute;
		left: auto;
		right: 0;
		top: 24%;
		-webkit-transform: translateY(-50%);
		    -ms-transform: translateY(-50%);
		        transform: translateY(-50%);
		max-width: 141px;
	}

	.about-wrap .about-img.image-df {
		position: absolute;
		right: auto;
		left: 11px;
		top: 66%;
		-webkit-transform: translateY(-50%);
		    -ms-transform: translateY(-50%);
		        transform: translateY(-50%);
	}

	.about-wrap .about-box-2.box-3 {
        text-align: right;
        padding-left: 0;
		padding-right: 30px;
        margin-left: 0;
        margin-right: 0;
        background: #FDE16F;
    }

	.about-wrap .about-text-box {
		width: 100%;
		background: #E7F5FE;
		padding-top: 21px;
		padding-left: 30px;
		padding-bottom: 20px;
		padding-right: 20px;
		border-radius: 21px;
		position: relative;
		z-index: -1;
	}
	.about-wrap .about-text-box h4 {
        font-size: 19px;
        color: #fff;
        font-weight: 600;
        margin-bottom: 20px;
    }
	.about-wrap .about-text-box p {
		font-size: 14px;
		color: #000000;
		margin-bottom: 21px;
	}
	.about-box-2.box-3.mobile-scrn {
        display: block;
        width: 100%;
        margin-left: 0;
    }
	.about-box-2.box-3.mobile-scrn {
        display: block;
    }
	.post-preview h2 {
		font-size: 28px !important;
	}
	.benifit-area {
		background: #D5F2F8;
		padding-top: 50px;
		padding-bottom: 50px;
	}
	.benifit-area .benifit-fx {
		display: block;
		-webkit-justify-content: center;
		    -ms-flex-pack: center;
		        justify-content: center;
		-webkit-flex-wrap: wrap;
		    -ms-flex-wrap: wrap;
		        flex-wrap: wrap;
	}
	.benifit-area .single-benifit {
		padding: 22px 0;
		width: 100%;
		margin: 0;
		margin-bottom: 30px;
	}
	.program-aera .program-fx-5 {
		width: 100%;
	}
	.program-aera .single-program {
		width: 100%;
		display: block;
	}
	
	#hdrtxt h3{
		font-size: 18px;
	  }
	  #hdrtxt h1{
		font-size: 28px;
		padding:10%;
	  }
	.video-wrapper{
		height: 250px;
	}

	#join-btn {
		margin-left: -0.25em;
	}
	.site-heading {
		margin-left: 2em;
	}
	.header-main-menu {
		display: none;
	}
	.hero-text {
		padding-top: 10%;
	}
	.hero-text span {
		font-size: 32px;
		line-height: 3rem;
	}
	.client-fx {
		width: 100%;
		-webkit-flex-wrap: wrap;
		    -ms-flex-wrap: wrap;
		        flex-wrap: wrap;
	}
	.header-right-menu-blk ul li {
		margin-left: 0;
	}
	.header-right-menu-blk {
		display: none;
	}
	.bar {
		position: absolute;
		top: 50%;
		-webkit-transform: translateY(-50%);
		    -ms-transform: translateY(-50%);
		        transform: translateY(-50%);
		left: 28px;
		z-index: 9;
	}
	.bar a {
		font-size: 23px;
		color: #000;
	}
	.offcanva-menu ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}
	.offcanva-menu ul li a {
		font-size: 17px;
		padding: 11px 15px;
		display: block;
		color: #000;
		text-transform: capitalize;
		border-bottom: 1px solid #ddd9;
		transition: .3s;
	}
	.offcanva-menu ul li a:hover {
		color: #36C7CE;
		border-color: #36C7CE;
		padding-left: 31px;
	}
	.offcanva {
		position: fixed;
		width: 90%;
		height: 100%;
		background: #fff;
		z-index: 33;
		transition: .3s;
		left: -100%;
	}
	.overlay {
		width: 100%;
		height: 100%;
		background: #000;
		position: fixed;
		opacity: .3;
		z-index: 9;
		top: 0;
		left: -100%;
		transition: .3s;
	}
	.offcanva-menu {
		margin-top: 93px;
	}
	.cross {
		position: absolute;
		top: 10px;
		right: 21px;
	}
	.cross a {
		font-size: 25px;
		color: #000;
	}
	.offcanva.active {
		left: 0;
	}
	.overlay.active {
		left: 0;
	}
	.offcanva {
		display: block;
	}
	.bar {
		display: block;
	}
	.bar a {
		color: #FFC600;
	}
	.header-logo {
		margin-left: 0;
		position: relative;
	}
	.header-area {
		height: 88px;
	}
	.header-main-menu ul li a {
		font-size: 16px;
	}
	.header-main-menu ul li {
		padding-right: 23px;
	}
	.header-right-menu-blk ul li a {
		font-size: 16px;
	}
	.header-right-menu-blk ul li {
		margin-left: 22px;
	}
	.header-logo {
		margin-left: 2px;
	}
	.section-title h2 {
		font-size: 26px;
	}
	.norm .why-area h2 {
		font-size: 26px;
	}
	.choose-content h3 {
		font-size: 25px !important;
		margin-bottom: 10px !important;
	}
	.choose-content h4 {
		font-size: 20px !important;
		margin-bottom: 11px !important;
	}
	.choose-content p {
		font-size: 16px !important;
		line-height: 23px !important;
		margin-bottom: 20px !important;
		height: auto;
	}
	.why-area .section-title {
		margin-bottom: 10px;
	}
	.why-thumb img {
		height: auto;
	}
	.why-content h3 {
		font-size: 28px;
	}
	.why-content h4 {
		font-size: 18px;
		line-height: 22px;
		margin-bottom: 4px;
	}
	.why-inner {
		height: 62px;
	}
	.color-disd {
		color: #FF80B1 !important;
		margin-bottom: 4px !important;
	}
	.navbar-brand {
		font-size: 32px !important;
		font-weight: 600 !important;
		color: white;
	}
	.choose-area {
		padding: 31px 0;
	}
	.single-slider-item .slider-content h4 {
		font-size: 24px;
		line-height: 48px;
		margin-bottom: 2px;
	}
	.single-slider-item .slider-content h5 {
		font-size: 20px;
		margin-bottom: 13px;
	}
	.single-slider-item .slider-content p {
		font-size: 16px;
		line-height: 22px;
		padding-right: 0;
	}
	.sider-area {
		padding: 42px 0;
	}
	.single-far-brand {
		width: 226px;
		height: 360px;
		border-radius: 40px;
		padding: 41px 10px;
		margin: auto;
		margin-bottom: 84px;
	}
	.single-far-brand::before {
		width: 264px;
	}
	.for-brand-fl {
		max-width: 100%;
	}
	.far-top-box p {
		font-size: 21px;
		line-height: 29px;
	}
	.far-area {
		padding-bottom: 0;
		padding-top: 45px;
	}
	.student-wrap {
		width: 100%;
		padding: 15px;
		padding-bottom: 40px;
		position: relative;
	}
	.student-content {
		width: 100%;
	}
	.single-far-brand.b2 {
		margin-bottom: 30px;
	}
	.student-content h3 {
		font-size: 30px;
		margin-bottom: 16px;
	}
	.student-wrap a {
		top: 19%;
	}
	.student-content p {
		font-size: 18px;
		line-height: 25px;
	}
	.student-aera {
		padding-top: 30px;
		padding-bottom: 45px;
	}
	.section-title h3 {
		font-size: 28px;
		margin-bottom: 8px;
	}
	.Engage-title h3 {
		font-size: 33px;
		margin-bottom: 40px;
	}
	.Engage-blk img {
		margin-bottom: 44px;
		height: 200px;
		width: 200px;
	}
	.choose-btn.choose-bty a {
		width: 189px;
		font-size: 17px;
	}
	.footer-list ul li a {
		font-size: 17px;
		line-height: 20px;
		margin-bottom: 10px;
	}
	.footer-body-text p {
		font-size: 15px;
		line-height: 24px;
		margin: 0;
	}
	.footer-social ul li a {
		font-size: 22px;
		margin-bottom: 11px;
	}
	.footer-area {
		padding: 0;
		padding-top: 40px;
		padding-bottom: 0;
	}
	.footer-social ul li {
		display: inline-block;
		margin: 0 12px;
	}
	.footer-social ul {
		padding: 0;
	}
	.footer-blk {
		text-align: center;
		margin-bottom: 40px;
	}
	.student-wrap a {
		display: block;
		width: 200px;
		text-align: center;
		line-height: 42px;
		height: 45px;
		font-size: 18px;
		top: auto;
		bottom: -15px;
		right: 20px;
	}
	.single-input input {
		font-size: 20px;
		margin-bottom: 24px;
	}
	.choose-btn {
		margin-bottom: 28px;
	}
	.footer-logo {
		margin-bottom: 29px;
	}
	.cheack-area {
		padding: 40px 0;
	}
	.section-title p {
		font-size: 16px;
		line-height: 26px;
		margin-bottom: 19px;
	}
	.slider-active .owl-nav button {
		left: 0;
	}
	.slider-active .owl-nav button.owl-next {
		left: auto;
		right: 0;
	}
	.choose-btn a {
		width: 243px;
		height: 45px;
		line-height: 39px;
		font-size: 18px;
	}
	.single-slider-item .slider-content h4 br {
		display: none;
	}
	.dropdown, .dropleft, .dropright, .dropup {
		position: relative !important;
		padding-left: 3rem !important;
		color: white;
	}
	.navbar-light .navbar-nav .nav-link {
		color: white;
	}
	.navbar-light .navbar-toggler {
		color: #7ce3e8 !important;
		border-color: #ffc107 !important;
	}
	.far-area .section-title {
		margin-bottom: 45px;
	}
	.far-top-box {
		width: 100%;
		height: 170px;
		padding: 0 1px;
		margin: 0 auto;
		margin-bottom: 30px;
	}
	.for-brand-fl {
		max-width: 99%;
		margin: auto;
		display: block;
		-webkit-justify-content: center;
		    -ms-flex-pack: center;
		        justify-content: center;
		-webkit-flex-wrap: wrap;
		    -ms-flex-wrap: wrap;
		        flex-wrap: wrap;
	}
	.single-far-brand span {
		height: 20px;
		width: 20px;
		left: -34px;
	}
	.single-far-brand span:nth-child(2) {
		right: -33px;
		left: auto;
	}
	.far-top-fl {
		max-width: 1180px;
		margin: auto;
		display: block;
		-webkit-align-items: center;
		    -ms-flex-align: center;
		        align-items: center;
		-webkit-justify-content: space-between;
		    -ms-flex-pack: justify;
		        justify-content: space-between;
		margin-bottom: 80px;
	}
	.video-wrapper {
		margin-bottom: 22px;
	}
	.student-title h3 {
		line-height: 68px;
		font-size: 36px;
	}
	.student-hat img {
		height: 80px;
	}
	.section-title {
		margin-bottom: 30px;
	}
	.footer-widget h4 {
		font-size: 17px;
		margin-bottom: 8px;
	}
	.header-logo a img {
		height: 68px;
	}
	.why-content p {
		font-size: 16px;
		line-height: 24px;
		margin: 0;
	}
	.student-hat img {
		height: 60px;
		margin-bottom: 20px;
	}
	.Engage-area {
		padding-bottom: 30px;
		padding-top: 20px;
	}
	.choose-content {
		padding: 18px !important;
	}
	.why-content {
		margin-bottom: 20px;
	}
	.slider-active .owl-nav button {
		display: none;
	}
	.single-far-brand.b2 {
		margin-bottom: 30px;
	}
	.box-fx {
		display: block;
	}
	.student-content.cnt-2 h3 {
		font-size: 24px;
		margin-bottom: 10px;
	}
	.hero-containt a img {
		max-width: 120px;
		text-align: center;
		margin: auto;
	}
	.hero-containt h3 {
		font-size: 30px;
		text-align: center;
	}
	.hero-containt p {
		font-size: 16px;
		text-align: center;
	}
	.bd-video {
		height: 400px !important;
	}
	.bg-videp {
		height: 100%;
	}
	#myVideo {
		height: 100%;
		background-size: cover;
		object-fit: cover;
	}
	.hero-containt a {
		text-align: center;
	}
	.single-client {
		width: 50%;
		text-align: center;
	}
	.bd-video {
		height: 700px;
	}
	.work-area {
        padding-bottom: 0 !important;
    }
	.work-area .work-all-fl {
        display: block;
        -webkit-align-items: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
                justify-content: space-between;
        padding: 0;
        text-align: center;
    }
	.single-work-box {
        width: 100% !important;
		height: 550px !important;
		padding: 13px 19px !important;
		margin-bottom: 30px;
    }
}
.footer-area{
    padding: 50px 0;
    background-color: black !important;
    bottom: 0;
    width:100%;
    text-align: left;
}

h1 {
    font-size: 60px;
}

li{
    list-style: none;
    margin-top: 12px;
}

a:link { 
    color: white; 
    text-decoration: none; 
} 

a:visited { 
    color:white; 
    text-decoration: none; 
} 

a:hover { 
    color: white; 
    text-decoration: none; 
}

.footer-logo{
    margin-bottom: 40px;
}

.footer-widget h4 {
    font-size: 25px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 25px;
}

.justify-content-center {
    -ms-flex-pack: center!important;
    -webkit-justify-content: center!important;
            justify-content: center!important;
}

.single-input input {
    width: 100%;
    border: 0;
    font-size: 18px;
    color: #707070;
    background: none;
    border-bottom: 1px solid #ADB8BC;
    margin-bottom: 24px;
}

.btn:not(:disabled):not(.disabled) {
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 32px;
    background: #f8c828;
    border-style: none;
    padding-right: 25px;
    padding-left: 25px;
    margin-top: 0px;
}

.btn:not(:disabled):not(.disabled) {
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 32px;
    background: #f8c828;
    border-style: none;
    padding-right: 25px;
    padding-left: 25px;
    margin-top: 0px;
}


.parallax-container {
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  position: relative;
  width: 100%;
}

.parallax-background {
  height: auto;
  -webkit-filter: brightness(50%);
          filter: brightness(50%);
  min-width: 100%;
}

.parallax-background, .parallax-placeholder {
  -webkit-filter: brightness(50%);
          filter: brightness(50%);
}

.parallax-placeholder {
  position: absolute;
  z-index: -200;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  border: 5em;
}

.parallax-content {
  min-height: 70vh;
  color: white;
  padding: 2em 1rem;
}

.parallax-content p {
  font-size: 1.25em;
  text-align: center;
}

.parallax-content .btn {
  width: 100px;
  /*margin: auto;*/
}

@media (min-width: 576px) {
  .parallax-content h1 {
    font-family: arial black;
    font-size: 50px;
    color: white;
    margin: 0px;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
  }
}


.container{
    max-width: 1570px;
}
.selector-for-some-widget {
  box-sizing: content-box;
}
a:hover { text-decoration: none }

button:focus {
	outline: 0;
	outline: 5px auto -webkit-focus-ring-color;
}

.goog-te-banner-frame.skiptranslate {
    display: none !important;
    }
body {
    top: 0px !important;
    }
/* Header bar section start */

.header-area {
	background: #000000;
	height: 113px;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-align-items: center;
	    -ms-flex-align: center;
	        align-items: center;
}
.header-main-menu ul li {
	display: inline-block;
	list-style: none;
	padding-right: 82px;
}
.header-right-menu-blk ul li.active a {
	color: #fff;
  font-size: 22px;
	text-decoration: underline;
}
.header-main-menu ul li a {
	font-size: 20px;
	color: #F2DE96;
	font-weight: 600;
	text-transform: capitalize;
	display: block;
	padding: 20px 0;
}
.header-right-menu-blk ul li a {
	font-size: 20px;
	color: #F2DE96;
	font-weight: 600;
	text-transform: capitalize;
}
.header-right-menu-blk ul li {
	display: inline-block;
	margin-left: 48px;
}
.header-site-right {
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-align-items: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-justify-content: flex-end;
	    -ms-flex-pack: end;
	        justify-content: flex-end;
}
.header-right-menu-blk ul {
	list-style: none;
	padding: 0;
}
.header-right-menu-blk ul {
	list-style: none;
	margin: 0;
}
.header-logo {
	margin-left: 92px;
}
.header-menu ul {
  padding: 0;
  margin: 0;
  list-style: none;
}


.header-fl {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.header-area {
  height: 140px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.header-left {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.header-menu ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.header-menu li {
  display: inline-block;
  margin: 0 5px;
}
.header-menu li a {
  color: #87868E;
  font-size: 20px;
  font-weight: 600;
  padding: 3px 30px;
  display: inline-block;
}
.header-right {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.header-logo {
  margin-left: 70px;
}
.header-menuu {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.header-menuu a {
  color: #87868E;
  font-size: 20px;
  font-weight: 600;
  margin: 0 5px;
  padding: 3px 20px;
}
/* Header bar section  END */

.dropdown-toggle {
  background: transparent;
  border: 0;
  font-size: 25px;
}


/* Hero area css Start */
#myVideo {
	width: 100%;
	border-radius: 30px;
}

.bd-video {
  position: relative;
  height: 970px;
}

.bg-videp {
  height: 100%;
}
#myVideo {
  height: 100%;
  object-fit: cover;
}
.container.ppd {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
}
.hero-containt a {
  margin-bottom: 14px;
  display: block;
}
.hero-containt h3 {
  font-size: 94px;
  color: #FFFFFF;
  font-weight: 700;
  text-shadow: 3px 6px 12px #000;
  margin: 0;
}
.hero-containt p {
  margin: 0;
  font-size: 52px;
  color: #fff;
  font-weight: 700;
  text-shadow: 3px 6px 12px #000;
}
/* Hero area css End */



/* Choose area css Start */
.choose-area {

	padding: 80px 0;
}
.choose-content {

	text-align: center;
	padding: 11px 67px;
  height: auto;
  padding-bottom: 3rem;
}

.section-title h2 {
  font-size: 88px;
	color: #000000;
	font-family: 'Segoe UI';
	margin: 0;
	font-weight: bold;
}

.section-title h3 {
  font-size: 70px;
  color: #4E4E65;
  font-weight: 600;
  margin: 0;
  line-height: 49px;
}

.choose-content h3 {
	font-size: 53px;
	color: #37C8CF;
	margin: 0;
	font-weight: bold;
}
.choose-content p {
	font-size: 25px;
	color: #707070;
	font-weight: normal;
	line-height: 30px;
	margin-bottom: 20px;

}
.choose-btn a {
	background: #37C8CF;
	width: 180px;
	height: 52px;
	display: inline-block;
	text-align: center;
	line-height: 51px;
	border-radius: 30px;
	font-size: 20px;
	color: #F8F4EB;
	transition: .3s;
	border: 1px solid transparent;


}

.offcanva {
	display: none;
}
.choose-btn a:hover {
	background: transparent;
	border: 2px solid #37C8CF;
	color: #37C8CF;
}
.choose-btn a img {
	margin: 0 11px;
}
.choose-blk {
	box-shadow: 0px 4px 12px 8px #E9E9E9;
}
.choose-thumb img {
	width: 100%;
}
.choose-btn {
	margin-bottom: 16px;
}
.choose-content h4 {
	font-size: 34px;
	color: #000000;
	font-weight: 600;
	margin-bottom: 14px;
}
.color-fr {
	color: #FF80B1 !important;
}
.choose-btn.color-btn a {
	background: #FF80B1;
}
/* Choose area css End */



/* Why area css Start */
.norm {
    background-blend-mode: overlay;
    background: rgba(0,0,0,.8);
    background-image: url(/static/media/hdImg6.cc487b48.jpg);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    color: #fff;
    padding: 80px 0;
}

.norm .why-area h2{
  color: white;
}

.why-area {
	padding-bottom: 85px;
	padding-top: 30px;
}
.why-area .container {
	max-width: 1190px;
}
.why-area .section-title {
	margin-bottom: 91px;
}
.why-content h3 {
	font-size: 47px;
	color: #FFC600;
	font-weight: bold;
	margin: 0;
}
.why-content h4 {
	font-size: 31px;
	color: #000000;
	font-weight: 600;
	line-height: 41px;
	margin-bottom: 8px;
}
.why-content p {
	font-size: 20px;
	color: #000000;
	font-weight: 400;
	line-height: 27px;
	margin: 0;
}
.why-inner {
	height: 70px;
}
.color-tr {
	color: #37C8CF !important;

}
.color-disd {
	color: #FF80B1 !important;

}
/* Whay area css End */



/* Slider area css Start */
.sider-area {
	background: #000000cf;
	padding: 114px 0;
	padding-left: 50px;
	padding-right: 50px;
}

.sider-area .section-title h2{
	color: white;
  font-size: 88px;
}

.slider-content h4 {
	font-size: 64px;
	color: #fff;
	font-weight: 400;
	line-height: 84px;
	margin-bottom: 13px;
}

.slider-content h5 {
	font-size: 32px;
	color: #fff;
	font-weight: 400;
	margin-bottom: 13px;
}

.slider-content p {
	font-size: 22px;
	color: #fff;
	font-weight: 400;
	line-height: 30px;
	margin: 0;
	padding-right: 28px;
}

.slider-active .owl-dots button {
	width: 33px;
	height: 6px;
	content: "";
	margin: 0 5px;
	border: 1px solid#37C8CF;
  transition: .3s;
}

.slider-active .owl-dot.active {
	background: #37C8CF !important;
}

.slider-active .owl-dots {
	text-align: center;
	max-width: 1008px;
}

.slider-active .owl-nav button {
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	        transform: translateY(-50%);
	left: -54px;
	font-size: 60px !important;
	color: #8E638B !important;
}
.slider-active .owl-nav button.owl-next {
	left: auto;
	right: -60px;
}
/* Slider area css End */

/* Num area css start*/
.numbers {
    background-blend-mode: overlay;
    background: rgba(0,0,0,.8);
    background-image: url(/static/media/achiv.25b94ea3.png);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    color: #fff;
    padding: 80px 0;
}

.num{
	color: white;
}

/* serv-us area start*/
.serv-us {
    background: #ffff;
    /* background-image: url(/img/e90aafe…/1.jpg); */
    background-size: cover;
    background-attachment: fixed;
    background-position: 50% 0% !important;
    background-blend-mode: overlay;
    color: #eee;
    padding: 60px 0;
}

.student-title {
    text-align: center;
    margin-bottom: 25px;
}
.student-title h3 {
    margin: 0;
    font-size: 92px;
    color: white;
    line-height: 122px;
    font-weight: 700;
}

.serv-us .our-video {
    position: relative;
    box-shadow: 0px 0px 10px #333;
    border-radius: 4px;
}

.v-middle {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    -webkit-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
}

.text-center {
    text-align: center!important;
}
/* serv-us area end*/

/* hero area start*/
.hero {
    background-blend-mode: overlay;
    background: rgb(0 0 0 / 67%);
    background-image: url(/static/media/hdImg8.e9432299.jpg);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    color: #fff;
    padding: 80px 0;
}

.hero {
    padding: 40px;
}

.hero-item{
	padding: 40px;
	margin: auto;
}

.clear-fix {
    clear: both !important;
}

.meet:hover{
	border: 2px solid #ffc600;
}

.learn:hover{
	border: 2px solid #37c8cf;
}

.discover:hover{
	border: 2px solid #ff80b1;
}


.learn
/* hero area end*/

/* Testimonials start */
.t-margin {
	margin-top: 0em;
	font-weight: 500;
}

/* Testimonials end */

.adjust-img-size {
	width: 50%;
	height: auto;
}

p {
    line-height: 1.5;
    margin: 12px 0;
    margin-top: 12pt;
    font-size: 22px;
    font-family: Montserrat, sans-serif;
}

.btn:hover {
    color: #212529;
    text-decoration: none;
    background: #65c6ce;
}


.react-parallax-background-children{
	width: 100%;
}
.aboutus {
    background-blend-mode: overlay;
    background: rgba(0,0,0,.68);
    background-image: url(/static/media/eziIntro.01ff7775.png);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    /*color: #fff;*/
    padding: 80px 0;
}

.header {
    min-height: 100vh;
    height: auto;
    width: 100%;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
}

.header-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(34,34,34,.4);
}

.caption {
    margin-top: 30px;
    /*color: #fff;*/
    letter-spacing: 4px;
    text-transform: uppercase;
}

.v-middle {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    -webkit-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
}

.caption h1 {
    margin: 50px auto;
    letter-spacing: 10px;
}

.aboutus .vid {
    display: inline-block;
}

article > h1, p {
    display: block;
    margin-top: 16px;
    padding-bottom: 16pt;
}

.features-boxed {
    color: #313437;
    background-color: #eef4f7;
}

.features-boxed .intro {
    font-size: 16px;
    max-width: 500px;
    margin: 0 auto;
}

.features-boxed .features {
    padding: 50px 0;
}

.features-boxed .item {
    text-align: center;
}

.features-boxed .item .box {
    text-align: center;
    padding: 30px;
    background-color: #fff;
    margin-bottom: 30px;
}

.box {
    border: 1px solid rgba(255,255,255,.08);
    background: rgba(255,255,255,.05);
    padding: 80px 20px;
    overflow: hidden;
}

.team-boxed {
    color: #313437;
    background-color: #eef4f7;
}

.team-boxed .intro {
    font-size: 16px;
    max-width: 500px;
    margin: 0 auto;
}

.team-boxed .people {
    padding: 50px 0;
}

.team-boxed .item {
    text-align: center;
}

.team-boxed .item .box {
    text-align: center;
    padding: 30px;
    background-color: #fff;
    margin-bottom: 30px;
}

.team-boxed .item .name {
    font-weight: bold;
    margin-top: 28px;
    margin-bottom: 8px;
    color: inherit;
}

.team-boxed .item .title {
    text-transform: uppercase;
    font-weight: bold;
    color: #d0d0d0;
    letter-spacing: 2px;
    font-size: 13px;
}
.homestay {
    background-blend-mode: overlay;
    background: rgba(0,0,0,.68);
    background-image: url(/static/media/plcHdr.f8c69a83.png);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    color: #fff;
    padding: 80px 0;
}


.header {
    min-height: 100vh;
    height: auto;
    width: 100%;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
}

.header-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(34,34,34,.4);
}

.caption {
    margin-top: 30px;
    color: #fff;
    letter-spacing: 4px;
    text-transform: uppercase;
}

.v-middle {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    -webkit-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
}

.caption h1 {
    margin: 50px auto;
    letter-spacing: 10px;
}

.homestay .vid {
    display: inline-block;
}

.feature-area {
    background: transparent linear-gradient(180deg, #E8F5FE 0%, #D2F2F8 100%) 0% 0% no-repeat padding-box;
    padding: 100px 0;
}

.feature-text h3 {
    font-size: 25px;
    color: #F595BA;
    font-weight: 600;
    margin-bottom: 13px;
}

.feature-single-icon {
    width: 215px;
    height: 215px;
    background: var(--unnamed-color-fdaed1) 0% 0% no-repeat padding-box;
    background: #FDAED1 0% 0% no-repeat padding-box;
    display: inline-block;
    line-height: 215px;
    border-radius: 50px;
    font-size: 86px;
    color: #fff;
}

.single-feature.cs .feature-single-icon{
    background: #7CE3E8;
}

.single-feature.ts .feature-single-icon{
    background: #FDE16F;
}

.section-title {
    text-align: center;
    margin-bottom: 49px;
}

.section-title h3 {
    font-size: 50px;
    line-height: 65px;
    color: #4E4E65;
    font-weight: bold;
    margin-bottom: 37px;
}

.feature-fl {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 0 100px;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.single-feature {
    width: 390px;
    text-align: center;
}

.feature-single {
    margin-bottom: 15px;
    position: relative;
}

.what-area {
    background: #F9FAFF 0% 0% no-repeat padding-box;
    padding: 80px 0;
    padding-bottom: 10px;
}

.what-fl {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    padding: 0 100px;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.single-what {
    width: 390px;
    margin-bottom: 75px;
}

.what-icon {
    margin-bottom: 15px;
}

.what-icon a {
    background: transparent linear-gradient(180deg, var(--unnamed-color-fdaed1) 0%, var(--unnamed-color-7ce3e8) 100%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(180deg, #FDAED1 0%, #7CE3E8 100%) 0% 0% no-repeat padding-box;
    width: 70px;
    height: 70px;
    display: inline-block;
    font-size: 30px;
    line-height: 70px;
    text-align: center;
    color: #fff;
}

.what-text p {
    font-size: 20px;
    color: #000000;
    line-height: 33px;
    margin: 0;
}

.what-icon a {
    transition: .3s;
}

.single-what.a2 a {
background: transparent linear-gradient(180deg, var(--unnamed-color-7ce3e8) 0%, var(--unnamed-color-fde16f) 100%) 0% 0% no-repeat padding-box;
background: transparent linear-gradient(180deg, #7CE3E8 0%, #FDE16F 100%) 0% 0% no-repeat padding-box;
}
.single-what.a3 a {
background: transparent linear-gradient(180deg, var(--unnamed-color-fde16f) 0%, var(--unnamed-color-fdaed1) 100%) 0% 0% no-repeat padding-box;
background: transparent linear-gradient(180deg, #FDE16F 0%, #FDAED1 100%) 0% 0% no-repeat padding-box;
}
.single-what.a4 a {
background: transparent linear-gradient(180deg, var(--unnamed-color-fde16f) 0%, var(--unnamed-color-fdaed1) 100%) 0% 0% no-repeat padding-box;
background: transparent linear-gradient(180deg, #FDE16F 0%, #FDAED1 100%) 0% 0% no-repeat padding-box;
}
.single-what.a4 a {
background: transparent linear-gradient(180deg, #FDAED1 0%, #7CE3E8 100%) 0% 0% no-repeat padding-box;
background: transparent linear-gradient(180deg, var(--unnamed-color-fde16f) 0%, var(--unnamed-color-fdaed1) 100%) 0% 0% no-repeat padding-box;
background: transparent linear-gradient(180deg, #FDE16F 0%, #FDAED1 100%) 0% 0% no-repeat padding-box;
}
.single-what.a5 a {
background: transparent linear-gradient(180deg, var(--unnamed-color-fdaed1) 0%, var(--unnamed-color-7ce3e8) 100%) 0% 0% no-repeat padding-box;
background: transparent linear-gradient(180deg, #FDAED1 0%, #7CE3E8 100%) 0% 0% no-repeat padding-box;
}
.single-what.a6 a {
background: transparent linear-gradient(180deg, var(--unnamed-color-7ce3e8) 0%, var(--unnamed-color-fde16f) 100%) 0% 0% no-repeat padding-box;
background: transparent linear-gradient(180deg, #7CE3E8 0%, #FDE16F 100%) 0% 0% no-repeat padding-box;
}

.what-icon:hover a {
    -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
            transform: scale(1.1);
}

/*work-area-start*/

.work-area {
background: #F9FAFF 0% 0% no-repeat padding-box;
padding-bottom: 100px;
}

.section-title {
text-align: center;
margin-bottom: 49px;
}

.section-title h3 {
    font-size: 70px;
    color: #4E4E65;
    font-weight: 600;
    margin: 0;
    line-height: 49px;
}

.work-all-fl {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 0 100px;
}

.single-work-box {
    width: 385px;
    height: 456px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px var(--unnamed-color-fde16f);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #FDE16F;
    padding: 45px 40px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    transition: .3s;
}

.work-img {
text-align: center;
height: 175px;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-align-items: center;
    -ms-flex-align: center;
        align-items: center;
-webkit-justify-content: center;
    -ms-flex-pack: center;
        justify-content: center;
}

.work-img img {
    transition: .3s;
}

.work-text h3 {
letter-spacing: 0.38px;
color: #FDE16F;
font-size: 32px;
font-weight: 600;
margin-bottom: 7px;
line-height: 39px;
}

.work-text {
text-align: center;
}

.work-text p {
font-size: 20px;
line-height: 35px;
color: #58595B;
font-weight: 400;
margin: 0;
}

.work-btn a {
color: #FDE16F;
font-size: 20px;
font-weight: 35px;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-align-items: center;
    -ms-flex-align: center;
        align-items: center;
}

.work-btn a i {
margin-left: 10px;
}

.single-work-box.sec {
background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px var(--unnamed-color-7ce3e8);
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #7CE3E8;
}

.single-work-box.sec h3 {
color: #7CE3E8;
}

.single-work-box.sec a {
color: #7CE3E8;
}

.single-work-box.thard {
background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px var(--unnamed-color-fdaed1);
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #FDAED1;
}

.single-work-box.thard h3 {
color: #FDAED1;
}

.single-work-box.thard a {
color: #FDAED1;
}

/*work-area-end*/

#viphosts .img-fluid {
    max-width: 300px;
    height: auto;
}

article > h1, p {
    display: block;
    margin-top: 16px;
    padding-bottom: 16pt;
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    outline: 0;
}

.fade {
    transition: opacity .15s linear;
}

.about-area.pdng {
    padding-top: 30px;
}

.about-wrap {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
}

.about-img {
    position: absolute;
    left: 15%;
    width: 166px;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    transition: .3s;
}

.about-text-box {
    width: 1202px;
    background: #E7F5FE;
    padding-top: 40px;
    padding-left: 130px;
    padding-bottom: 35px;
    padding-right: 80px;
    border-radius: 30px;
    position: relative;
    z-index: -1;
}

.t-margin {
    margin-top: 0em;
    font-weight: 500;
}

.about-text-box {
    width: 1202px;
    background: #E7F5FE;
    padding-top: 40px;
    padding-left: 130px;
    padding-bottom: 35px;
    padding-right: 80px;
    border-radius: 30px;
    position: relative;
    z-index: -1;
}

.about-text-box p {
    font-size: 20px;
    color: #000000;
    margin-bottom: 30px;
}

.about-wrap {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
}

.about-box-2 {
    background: #7CE3E8;
    width: 306px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 30px;
    margin-right: 40px;
}

.about-box-2.box-3 {
	text-align: right;
	padding-left: 0;
	padding-right: 40px;
	margin-left: 0;
	margin-right: 0;
	background: #FDE16F;
	margin-left: 40px;
}

.one {
    position: absolute;
    top: 36px;
    left: 95px;
}

.two {
    position: absolute;
    top: 40%;
    right: 35%;
}

.about-wrap.wrap-2 {
    -webkit-justify-content: end;
        -ms-flex-pack: end;
            justify-content: end;
}

.about-img.image-df {
    position: absolute;
    right: 16%;
    left: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
}

.about-text-box.text-box-3.thim-ng {
    background: #FFF6D8;
}

.about-area.pd-lsdt {
    padding-bottom: 30px;
}

.about-box-2.clr-cng {
    background: #FDAED1;
}

.modal-content {
    position: relative;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;
    outline: 0;
}

.modal-body {
    position: relative;
    -ms-flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    padding: 1rem;
}

.single-work-box:hover {
	background: #FDE16F;
}
.single-work-box.sec:hover {
	background: #7CE3E8;
}
.single-work-box.thard:hover {
	background: #FDAED1;
}
.single-work-box:hover .work-text h3 {
	color: #fff;
}
.single-work-box:hover .work-btn a {
	color: #fff;
}
/* header area starts */
.internship {
    background-blend-mode: overlay;
    background: rgba(0,0,0,.68);
    background-image: url(/static/media/onint.bf0697ed.png);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    color: #fff;
    padding: 80px 0;
}

.header {
    min-height: 100vh;
    height: auto;
    width: 100%;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
}

.header-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(34,34,34,.4);
}

.caption {
    margin-top: 30px;
    color: #fff;
    letter-spacing: 4px;
    text-transform: uppercase;
}

.v-middle {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    -webkit-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
}

.caption h1 {
    margin: 50px auto;
    letter-spacing: 10px;
}

.internship .vid {
    display: inline-block;
}

/* header area end */

/* work-area-start */

.work-area {
    background: #F9FAFF 0% 0% no-repeat padding-box;
    padding-bottom: 100px;
  }
  .section-title {
    text-align: center;
    margin-bottom: 49px;
  }
  .section-title h3 {
    font-size: 70px;
    color: #4E4E65;
    font-weight: 600;
    margin: 0;
    line-height: 49px;
  }
  .work-all-fl {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 0 100px;
  }
  .single-work-box {
      width: 385px;
      height: 456px;
      background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px var(--unnamed-color-fde16f);
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #FDE16F;
      padding: 45px 40px;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
              justify-content: space-between;
      transition: .3s;
}


  .work-img {
    text-align: center;
    height: 175px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .work-text h3 {
    letter-spacing: 0.38px;
    color: #FDE16F;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 7px;
    line-height: 39px;
  }
  .work-text {
    text-align: center;
  }
  .work-text p {
    font-size: 20px;
    line-height: 35px;
    color: #58595B;
    font-weight: 400;
    margin: 0;
  }
  
  .work-btn a {
    color: #FDE16F;
    font-size: 20px;
    font-weight: 35px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
  }

  .work-btn a i {
    margin-left: 10px;
  }

  .single-work-box.sec {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px var(--unnamed-color-7ce3e8);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #7CE3E8;
  }
  .single-work-box.sec h3 {
    color: #7CE3E8;
  }
  .single-work-box.sec a {
    color: #7CE3E8;
  }
  .single-work-box.thard {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px var(--unnamed-color-fdaed1);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #FDAED1;
  }
  .single-work-box.thard h3 {
    color: #FDAED1;
  }
  .single-work-box.thard a {
    color: #FDAED1;
  }

  .single-work-box.clr-1 {
    padding-top: 15px;
    padding-bottom: 26px;
    box-shadow: 0px 3px 6px var(--unnamed-color-fde16f);
    border: 1px solid var(--unnamed-color-fde16f);
    background: #FFF6D8 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #fde16f;
    border: 1px solid #FDE16F;
    opacity: 1;
}

.single-work-box.clr-2 {
    padding-top: 15px;
    padding-bottom: 26px;
    box-shadow: 0px 3px 6px var(--unnamed-color-7ce3e8);
    border: 1px solid var(--unnamed-color-7ce3e8);
    background: #E7F5FE 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #7ce3e8;
    border: 1px solid #7CE3E8;
    opacity: 1;
}

.single-work-box.thard.clr-3 {
    padding-top: 15px;
    padding-bottom: 26px;
    box-shadow: 0px 3px 6px var(--unnamed-color-fdaed1);
    border: 1px solid var(--unnamed-color-fdaed1);
    background: #FFE4F0 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #fdaed1;
    border: 1px solid #FDAED1;
    opacity: 1;
}


.single-work-box:hover {
	background: #FDE16F;
}
.single-work-box.sec:hover {
	background: #7CE3E8;
}
.single-work-box.thard:hover {
	background: #FDAED1;
}
.single-work-box:hover .work-text h3 {
	color: #fff;
}
.single-work-box:hover .work-btn a {
	color: #fff;
}
  /* work-area end */
  
  /* program-area start */
.program-aera {
    padding: 40px 0;
}

.section-title {
    text-align: center;
    margin-bottom: 49px;
}

.section-title h3 {
    font-size: 50px;
    line-height: 65px;
    color: #4E4E65;
    font-weight: bold;
    margin-bottom: 37px;
}

.program-fx-5 {
    width: 1009px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    margin: auto;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.single-program {
    max-width: 525px;
    background: #E7F5FE;
    border-radius: 10px;
    margin-bottom: 50px;
    transition: .3s;
}

.single-box-fx {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 30px;
    max-width: 478px;
    transition: .3s;
}

.program-icon {
    width: 172px;
    background: #34C5CC;
    height: 116px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    border-radius: 27px;
}

.program-icon img {
    max-width: 83px;
}

.single-program.pgr-2 {
    background: #FFF6D8;
}

.program-icon.icn-2 {
    background: #FBDA57;
}

.single-program.pgr-3 {
	background: #FFE4F0;
}

.program-icon.icn-3 {
	background: #F595BA;
}

.single-program.pgr-1:hover {
	background: #34C5CC;
}
.single-program.pgr-1:hover .program-content p {
	color: #fff;
}
.single-program.pgr-2:hover {
	background: #FBDA57;
}
.single-program.pgr-3:hover {
	background: #F595BA;
}
.single-program.pgr-4:hover {
	background: #34C5CC;
}

/* program area end */

/* choose area start*/
.mb-50 {
    margin-bottom: 50px !important;
}

.section-title h2 {
    font-size: 88px;
    color: #000000;
    font-family: 'Segoe UI';
    margin: 0;
    font-weight: bold;
}

.choose-blk {
    box-shadow: 0px 4px 12px 8px #e9e9e9;
}

.choose-thumb img {
    width: 100%;
}

.choose-content {
    text-align: center;
    padding: 11px 67px;
    height: auto;
    padding-bottom: 3rem;
}

.choose-content h3 {
    font-size: 53px;
    color: #37C8CF;
    margin: 0;
    font-weight: bold;
}

.choose-content h4 {
    font-size: 34px;
    color: #000000;
    font-weight: 600;
    margin-bottom: 14px;
}

.choose-content p {
    font-size: 22px;
    color: #707070;
    font-weight: normal;
    line-height: 30px;
    margin-bottom: 20px;
}

.choose-btn {
    margin-bottom: 16px;
}

.choose-btn a {
    background: #37C8CF;
    width: 180px;
    height: 52px;
    display: inline-block;
    text-align: center;
    line-height: 51px;
    border-radius: 30px;
    font-size: 18px;
    color: #F8F4EB;
    transition: .3s;
    border: 1px solid transparent;
}

.choose-btn a:hover{
    background: white;
}

.color-fr {
    color: #FF80B1 !important;
}
/* choose area end */

/* journey area start */
.journey {
    padding: 100px 0;
}

.journey-wrap {
    text-align: center;
}
/* journey area end */

/* benefit area start */
.benifit-fx {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.single-benifit.hov-1 {
    transition: .3s;
}

.single-benifit {
    width: 18%;
    text-align: center;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    border: 3px solid var(--unnamed-color-ffffff);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 3px 6px 20px #ffc60070;
    border: 3px solid #FFFFFF;
    border-radius: 12px;
    opacity: 1;
    padding: 40px 0;
}

.benifiut-text p {
	font-size: 24px;
	color: #4E4E65;
	line-height: 35px;
}

.single-benifit.ben-1 {
	background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
	border: 3px solid var(--unnamed-color-ffffff);
	background: #FFFFFF 0% 0% no-repeat padding-box;
	box-shadow: 3px 6px 20px #7CE3E88C;
	border: 3px solid #FFFFFF;
	border-radius: 12px;
	opacity: 1;
}

.single-benifit.ben-2 {
	background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
	border: 3px solid var(--unnamed-color-ffffff);
	background: #FFFFFF 0% 0% no-repeat padding-box;
	box-shadow: 3px 6px 20px #F595BA77;
	border: 3px solid #FFFFFF;
	border-radius: 12px;
	opacity: 1;
}

.single-benifit.ben-3 {
	background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
	border: 3px solid var(--unnamed-color-ffffff);
	background: #FFFFFF 0% 0% no-repeat padding-box;
	box-shadow: 3px 6px 20px #7CE3E88C;
	border: 3px solid #FFFFFF;
	border-radius: 12px;
	opacity: 1;
}

.benifit-area {
	background: #D5F2F8;
	padding-top: 200px;
	padding-bottom: 250px;
}

.single-benifit.hov-1 {
	transition: .3s;
}
.single-benifit.hov-1:hover {
	background: #FFC600;
}
.single-benifit.ben-1.hov-2:hover {
	background: #37C8CF;
}

.single-benifit.ben-3.hov-2:hover {
	background: #37C8CF;
}
.single-benifit.ben-2.hov-3:hover {
	background: #F595BA;
}
.single-benifit.ben-2.hov-3{
  transition: .3s;
}
.single-benifit.ben-1.hov-2 {
	transition: .3s;
}

/* benefit area end */

.btn:not(:disabled):not(.disabled) {
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 32px;
    background: #f8c828;
    border-style: none;
    padding-right: 25px;
    padding-left: 25px;
    margin-top: 0px;
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.post-heading, header.masthead .site-heading {
    padding: 200px 0 150px;
    color: #fff;
}


.site-heading {
    padding-top: 25vh;
}

.site-heading h1 {
    font-size: 69px !important;
    color: white !important;
    font-weight: 800 !important;
}

@media (min-width: 1px) and (max-width: 800px){
    .site-heading h1 {
        font-size: 36px !important;
    }
}

.post-preview{
    text-align: left;
}

.post-preview h2{
    font-size: 59px;
}


article{
    text-align: left;
}

.blog_home{
    background-blend-mode: overlay !important;
    background: rgba(0,0,0,0.4);
    height: 80vh !important;
    background-size: cover !important;
    background-position: center;
    background-attachment: fixed;
    color: #fff;
    background-image: url(/static/media/home-bg.6add1713.jpg);
}

.post_1{
    background-blend-mode: overlay !important;
    background: rgba(0,0,0,0.4);
    height: 80vh !important;
    background-size: cover !important;
    background-position: center;
    background-attachment: fixed;
    color: #fff;
    background: url(/static/media/blogBg_2.d199ce0c.jpg);
}

.post_2{
    background-blend-mode: overlay !important;
    background: rgba(0,0,0,0.4);
    height: 80vh !important;
    background-size: cover !important;
    background-position: center;
    background-attachment: fixed;
    color: #fff;
    background: url(/static/media/blogBg_2.d199ce0c.jpg);
}

.post_3{
    background-blend-mode: overlay !important;
    background: rgba(0,0,0,0.4);
    height: 80vh !important;
    background-size: cover !important;
    background-position: center;
    background-attachment: fixed;
    color: #fff;
    background: url(/static/media/blogImg3_1.fed33990.jpg) bottom / cover;
}

.post_4{
    background-blend-mode: overlay !important;
    background: rgba(0,0,0,0.4);
    height: 80vh !important;
    background-size: cover !important;
    background-position: center;
    background-attachment: fixed;
    color: #fff;
    background: url(/static/media/blogImg4_1.ba5277f9.jpg);
}

.post_5{
    background-blend-mode: overlay !important;
    background: rgba(0,0,0,0.4);
    height: 80vh !important;
    background-size: cover !important;
    background-position: center;
    background-attachment: fixed;
    color: #fff;
    background: url(/static/media/blog5hdr.f19b3528.png);
}

.post_6{
    background-blend-mode: overlay !important;
    background: rgba(0,0,0,0.4);
    height: 80vh !important;
    background-size: cover !important;
    background-position: center;
    background-attachment: fixed;
    color: #fff;
    background: url(/static/media/blogImg_5.85b46a5a.jpg) no-repeat;
    background-size: cover;
}

.post_7{
    background-blend-mode: overlay !important;
    background: rgba(0,0,0,0.4);
    height: 80vh !important;
    background-size: cover !important;
    background-position: center;
    background-attachment: fixed;
    color: #fff;
    background: url(/static/media/blog7_0.d29c7271.png) no-repeat;
    background-size: cover;
}

.post_8{
    background-blend-mode: overlay !important;
    background: rgba(0,0,0,0.4);
    height: 80vh !important;
    background-size: cover !important;
    background-position: center;
    background-attachment: fixed;
    color: #fff;
    background: url(/static/media/blog8_0.302653f1.png);
    background-size: cover;
}

.post_9{
    background-blend-mode: overlay !important;
    background: rgba(0,0,0,0.4);
    height: 80vh !important;
    background-size: cover !important;
    background-position: center;
    background-attachment: fixed;
    color: #fff;
    background: url(/static/media/blog8_2.ff6d86fb.png);
    background-size: cover;
}

.post_10{
    background-blend-mode: overlay !important;
    background: rgba(0,0,0,0.4);
    height: 80vh !important;
    background-size: cover !important;
    background-position: center;
    background-attachment: fixed;
    color: #fff;
    background: url(/static/media/blogBg_2.d199ce0c.jpg);
    background-size: cover;
}




.howitworks-header{
    background: url(/static/media/faq2_1.0be1b088.jpg);
    height: 80vh !important;
    color: #fff;
    background-size: cover; 
    background-position: center;
    background-blend-mode: overlay;
    background-color:#000000a9;
}

.host-terms{
    height: 80vh !important;
    color: #fff;
    background: url(/static/media/hdImg8.e9432299.jpg);
    background-position: center;
    background-size: cover;
}

.faq-header{
    height: 80vh !important;
    color: #fff;
    background: url(/static/media/faq2_1.0be1b088.jpg);
    background-position: center;
    background-size: cover; 
    background-blend-mode: overlay;
    background-color:#000000a9;
}

.site-heading {
    padding-top: 25vh;
}

.privacy-header{
    height: 80vh !important;
    background-position: center;
    color: #fff;
    background-image: url(/static/media/hdImg.a64e8a5e.jpg);
    background-size: cover;
}

.masthead {
    background-blend-mode: overlay !important;
    background: rgba(0,0,0,0.4);
    height: 80vh !important;
    background-size: cover !important;
    background-position: center;
    background-attachment: fixed;
    color: #fff;
}

.site-heading {
    padding-top: 25vh;
}

.invite-header{
    background: url(/static/media/invitefriend.d679bf5e.jpg); 
    background-position: center;
    height: 80vh !important;
    background-size:cover; 
    height:80vh;
}

.highlight-phone{
    background: url(/static/media/refbg2.756c6861.svg) no-repeat;
    background-size: cover;
    padding-top: 64px;
}
.contact-header{
    background-image: url(/static/media/cbg.1f1d841b.jpg);
    height: 50vh !important;
    color: #fff;
    background-size: cover; 
    background-position: center;
    background-blend-mode: overlay;
    background-color:#000000a9;
}

.card {
    background-color: #FFF;
    border-radius: 25px;
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
    padding: 40px;
    z-index: 0;
}

#progressbar {
    margin-bottom: 30px;
    overflow: hidden;
    color: lightgrey;
    padding-left: 0px;
}

fieldset.show {
    display: block;
}

.radio {
    display: inline-block;
    border-radius: 0;
    box-sizing: border-box;
    cursor: pointer;
    color: #BDBDBD;
    font-weight: 500;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    filter: grayscale(100%);
}

.radio:hover {
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1)
}

#next1 {
    background-color: #7ce3e8;
    color: black;
    border-color: #7ce3e8;
}

.radio.selected {
    border: 1px solid #fdaed1;
    box-shadow: 0px 8px 16px 0px #eeeeee;
    color: #29B6F6 !important;
    -webkit-filter: grayscale(0%);
    -moz-filter: grayscale(0%);
    -o-filter: grayscale(0%);
    -ms-filter: grayscale(0%);
    filter: grayscale(0%);
}

.card-block {
    border: 1px solid #CFD8DC;
    width: 45%;
    margin: 2.5%;
    padding: 20px 25px 15px 25px;
}

.image-icon {
    width: 100px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}

.fit-image {
    width: 100%;
    object-fit: cover;
}

.icon {
    width: 100px;
    height: auto;
}

.btn-block {
    border-radius: 5px;
    height: 50px;
    font-weight: 500;
    cursor: pointer;
}

fieldset.show {
    display: block;
}
.indexedStep {
    color: white;
    width: 20px;
    height: 20px;
    font-size: 12px;
    background-color: rgba(211, 211, 211, 0.8);
    border-radius: 50%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
  }
  
  .indexedStep.accomplished {
    background-color: rgba(0, 116, 217, 1);
  }
.signup-header{
    background: url(/static/media/signUp.829c16de.jpg); 
    height: 80vh !important;
    color: #fff;
    background-size: cover; 
    background-position: center;
    background-blend-mode: overlay;
    background-color:#000000a9;

}

.signup-header h1{
    font-size: 56px;
    color:white;
}
.masthead {
    background-blend-mode: overlay !important;
    background: rgba(0,0,0,0.4);
    height: 80vh !important;
    background-size: cover !important;
    background-position: center;
    background-attachment: fixed;
    color: #fff;
}

.Affiliates{
    background: url(/static/media/hdImg7.048ec1b1.jpg);
    background-size: cover; 
    height:80vh
}

#signUpform{
    text-align: left;
    border-radius: 10px;
    font-family: Montserrat, sans-serif;
    margin-bottom: 10rem;
}

.form-check-label{
    font-family: 'Open Sans', sans-serif;
}

