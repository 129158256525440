.homestay {
    background-blend-mode: overlay;
    background: rgba(0,0,0,.68);
    background-image: url(../../assets/img/plcHdr.png);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    color: #fff;
    padding: 80px 0;
}


.header {
    min-height: 100vh;
    height: auto;
    width: 100%;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
}

.header-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(34,34,34,.4);
}

.caption {
    margin-top: 30px;
    color: #fff;
    letter-spacing: 4px;
    text-transform: uppercase;
}

.v-middle {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
}

.caption h1 {
    margin: 50px auto;
    letter-spacing: 10px;
}

.homestay .vid {
    display: inline-block;
}

.feature-area {
    background: transparent linear-gradient(180deg, #E8F5FE 0%, #D2F2F8 100%) 0% 0% no-repeat padding-box;
    padding: 100px 0;
}

.feature-text h3 {
    font-size: 25px;
    color: #F595BA;
    font-weight: 600;
    margin-bottom: 13px;
}

.feature-single-icon {
    width: 215px;
    height: 215px;
    background: var(--unnamed-color-fdaed1) 0% 0% no-repeat padding-box;
    background: #FDAED1 0% 0% no-repeat padding-box;
    display: inline-block;
    line-height: 215px;
    border-radius: 50px;
    font-size: 86px;
    color: #fff;
}

.single-feature.cs .feature-single-icon{
    background: #7CE3E8;
}

.single-feature.ts .feature-single-icon{
    background: #FDE16F;
}

.section-title {
    text-align: center;
    margin-bottom: 49px;
}

.section-title h3 {
    font-size: 50px;
    line-height: 65px;
    color: #4E4E65;
    font-weight: bold;
    margin-bottom: 37px;
}

.feature-fl {
    display: flex;
    padding: 0 100px;
    justify-content: space-between;
}

.single-feature {
    width: 390px;
    text-align: center;
}

.feature-single {
    margin-bottom: 15px;
    position: relative;
}

.what-area {
    background: #F9FAFF 0% 0% no-repeat padding-box;
    padding: 80px 0;
    padding-bottom: 10px;
}

.what-fl {
    display: flex;
    flex-wrap: wrap;
    padding: 0 100px;
    justify-content: space-between;
}

.single-what {
    width: 390px;
    margin-bottom: 75px;
}

.what-icon {
    margin-bottom: 15px;
}

.what-icon a {
    background: transparent linear-gradient(180deg, var(--unnamed-color-fdaed1) 0%, var(--unnamed-color-7ce3e8) 100%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(180deg, #FDAED1 0%, #7CE3E8 100%) 0% 0% no-repeat padding-box;
    width: 70px;
    height: 70px;
    display: inline-block;
    font-size: 30px;
    line-height: 70px;
    text-align: center;
    color: #fff;
}

.what-text p {
    font-size: 20px;
    color: #000000;
    line-height: 33px;
    margin: 0;
}

.what-icon a {
    transition: .3s;
}

.single-what.a2 a {
background: transparent linear-gradient(180deg, var(--unnamed-color-7ce3e8) 0%, var(--unnamed-color-fde16f) 100%) 0% 0% no-repeat padding-box;
background: transparent linear-gradient(180deg, #7CE3E8 0%, #FDE16F 100%) 0% 0% no-repeat padding-box;
}
.single-what.a3 a {
background: transparent linear-gradient(180deg, var(--unnamed-color-fde16f) 0%, var(--unnamed-color-fdaed1) 100%) 0% 0% no-repeat padding-box;
background: transparent linear-gradient(180deg, #FDE16F 0%, #FDAED1 100%) 0% 0% no-repeat padding-box;
}
.single-what.a4 a {
background: transparent linear-gradient(180deg, var(--unnamed-color-fde16f) 0%, var(--unnamed-color-fdaed1) 100%) 0% 0% no-repeat padding-box;
background: transparent linear-gradient(180deg, #FDE16F 0%, #FDAED1 100%) 0% 0% no-repeat padding-box;
}
.single-what.a4 a {
background: transparent linear-gradient(180deg, #FDAED1 0%, #7CE3E8 100%) 0% 0% no-repeat padding-box;
background: transparent linear-gradient(180deg, var(--unnamed-color-fde16f) 0%, var(--unnamed-color-fdaed1) 100%) 0% 0% no-repeat padding-box;
background: transparent linear-gradient(180deg, #FDE16F 0%, #FDAED1 100%) 0% 0% no-repeat padding-box;
}
.single-what.a5 a {
background: transparent linear-gradient(180deg, var(--unnamed-color-fdaed1) 0%, var(--unnamed-color-7ce3e8) 100%) 0% 0% no-repeat padding-box;
background: transparent linear-gradient(180deg, #FDAED1 0%, #7CE3E8 100%) 0% 0% no-repeat padding-box;
}
.single-what.a6 a {
background: transparent linear-gradient(180deg, var(--unnamed-color-7ce3e8) 0%, var(--unnamed-color-fde16f) 100%) 0% 0% no-repeat padding-box;
background: transparent linear-gradient(180deg, #7CE3E8 0%, #FDE16F 100%) 0% 0% no-repeat padding-box;
}

.what-icon:hover a {
    transform: scale(1.1);
}

/*work-area-start*/

.work-area {
background: #F9FAFF 0% 0% no-repeat padding-box;
padding-bottom: 100px;
}

.section-title {
text-align: center;
margin-bottom: 49px;
}

.section-title h3 {
    font-size: 70px;
    color: #4E4E65;
    font-weight: 600;
    margin: 0;
    line-height: 49px;
}

.work-all-fl {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 100px;
}

.single-work-box {
    width: 385px;
    height: 456px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px var(--unnamed-color-fde16f);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #FDE16F;
    padding: 45px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: .3s;
}

.work-img {
text-align: center;
height: 175px;
display: flex;
align-items: center;
justify-content: center;
}

.work-img img {
    transition: .3s;
}

.work-text h3 {
letter-spacing: 0.38px;
color: #FDE16F;
font-size: 32px;
font-weight: 600;
margin-bottom: 7px;
line-height: 39px;
}

.work-text {
text-align: center;
}

.work-text p {
font-size: 20px;
line-height: 35px;
color: #58595B;
font-weight: 400;
margin: 0;
}

.work-btn a {
color: #FDE16F;
font-size: 20px;
font-weight: 35px;
display: flex;
align-items: center;
}

.work-btn a i {
margin-left: 10px;
}

.single-work-box.sec {
background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px var(--unnamed-color-7ce3e8);
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #7CE3E8;
}

.single-work-box.sec h3 {
color: #7CE3E8;
}

.single-work-box.sec a {
color: #7CE3E8;
}

.single-work-box.thard {
background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px var(--unnamed-color-fdaed1);
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #FDAED1;
}

.single-work-box.thard h3 {
color: #FDAED1;
}

.single-work-box.thard a {
color: #FDAED1;
}

/*work-area-end*/

#viphosts .img-fluid {
    max-width: 300px;
    height: auto;
}

article > h1, p {
    display: block;
    margin-top: 16px;
    padding-bottom: 16pt;
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    outline: 0;
}

.fade {
    transition: opacity .15s linear;
}

.about-area.pdng {
    padding-top: 30px;
}

.about-wrap {
    display: flex;
    align-items: center;
    position: relative;
}

.about-img {
    position: absolute;
    left: 15%;
    width: 166px;
    top: 50%;
    transform: translateY(-50%);
    transition: .3s;
}

.about-text-box {
    width: 1202px;
    background: #E7F5FE;
    padding-top: 40px;
    padding-left: 130px;
    padding-bottom: 35px;
    padding-right: 80px;
    border-radius: 30px;
    position: relative;
    z-index: -1;
}

.t-margin {
    margin-top: 0em;
    font-weight: 500;
}

.about-text-box {
    width: 1202px;
    background: #E7F5FE;
    padding-top: 40px;
    padding-left: 130px;
    padding-bottom: 35px;
    padding-right: 80px;
    border-radius: 30px;
    position: relative;
    z-index: -1;
}

.about-text-box p {
    font-size: 20px;
    color: #000000;
    margin-bottom: 30px;
}

.about-wrap {
    display: flex;
    align-items: center;
    position: relative;
}

.about-box-2 {
    background: #7CE3E8;
    width: 306px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 30px;
    margin-right: 40px;
}

.about-box-2.box-3 {
	text-align: right;
	padding-left: 0;
	padding-right: 40px;
	margin-left: 0;
	margin-right: 0;
	background: #FDE16F;
	margin-left: 40px;
}

.one {
    position: absolute;
    top: 36px;
    left: 95px;
}

.two {
    position: absolute;
    top: 40%;
    right: 35%;
}

.about-wrap.wrap-2 {
    justify-content: end;
}

.about-img.image-df {
    position: absolute;
    right: 16%;
    left: auto;
    top: 50%;
    transform: translateY(-50%);
}

.about-text-box.text-box-3.thim-ng {
    background: #FFF6D8;
}

.about-area.pd-lsdt {
    padding-bottom: 30px;
}

.about-box-2.clr-cng {
    background: #FDAED1;
}

.modal-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;
    outline: 0;
}

.modal-body {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem;
}

.single-work-box:hover {
	background: #FDE16F;
}
.single-work-box.sec:hover {
	background: #7CE3E8;
}
.single-work-box.thard:hover {
	background: #FDAED1;
}
.single-work-box:hover .work-text h3 {
	color: #fff;
}
.single-work-box:hover .work-btn a {
	color: #fff;
}