@media (min-width: 1449px) {
	.video-wrapper{
		height: 600px;
	}
}

/* XL Device :1200px. */

@media (min-width: 1200px) and (max-width: 1449px) {
	.single-what {
		width: 390px !important;
		margin-bottom: 30px !important;
	}
	.what-text h3 {
        font-size: 20px;
        margin-bottom: 7px;
    }
    .what-text p {
        font-size: 15px !important;
        line-height: 28px !important;
    }
	.about-box-2 {
        width: 297px;
        padding-left: 24px;
        border-radius: 30px;
    }
    .about-box-title h4 {
        font-size: 22px;
        color: #fff;
        font-weight: 600;
        margin-bottom: 21px;
    }
    .about-wrap {
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 40px;
    }
	.video-wrapper{
		height: 500px;
	}
	.header-main-menu ul li a {
		font-size: 18px;
	}
	.hero-text {
		padding-top: 20%;
	}
	.hero-text span {
		font-size: 60px;
		line-height: 3rem;
		padding-right: 15%;
	}
	.header-main-menu ul li {
		padding-right: 65px;
	}
	.header-right-menu-blk ul li a {
		font-size: 18px;
	}
	.header-right-menu-blk ul li {
		margin-left: 38px;
	}
	.header-logo {
		margin-left: 40px;
	}
	.section-title h2 {
		font-size: 64px;
		margin-top: 1.5em;
	}

	.norm .why-area h2 {
		font-size: 64px;
		margin-top: 1.5em;
	}

	.choose-content h3 {
		font-size: 32px !important;
	}
	.choose-content h4 {
		font-size: 24px !important;
		margin-bottom: 1.5em !important;
	}
	.choose-content p {
		font-size: 18px !important;
		line-height: 26px !important;
		margin-bottom: 2em !important;
	}
	.why-area .section-title {
		margin-bottom: 10px;
	}
	.why-thumb img {
		height: auto;
	}
	.why-content h3 {
		font-size: 38px;
	}
	.why-content h4 {
		font-size: 26px;
		line-height: 31px;
		margin-bottom: 8px;
	}
	.why-inner {
		height: 46px;
	}
	.color-disd {
		color: #FF80B1 !important;
		margin-bottom: 4px !important;
	}
	.choose-area {
		padding: 31px 0;
	}
	.single-slider-item .slider-content h4 {
		font-size: 40px;
		line-height: 58px;
		margin-bottom: 13px;
	}
	.single-slider-item .slider-content h5 {
		font-size: 28px;
		margin-bottom: 13px;
	}
	.single-slider-item .slider-content p {
		font-size: 20px;
		line-height: 26px;
		padding-right: 0;
	}
	.sider-area {
		padding: 42px 0;
	}
	.single-far-brand {
		width: 186px;
		height: auto;
		border-radius: 40px;
		padding: 41px 10px;
		text-align: center;
		position: relative;
	}
	.single-far-brand::before {
		width: 241px;
	}
	.for-brand-fl {
		max-width: 1100px;
	}
	.far-top-box p {
		font-size: 31px;
		line-height: 33px;
	}
	.far-area {
		padding-bottom: 90px;
		padding-top: 45px;
	}
	.student-wrap {
		width: 100%;
		padding: 15px;
		padding-bottom: 40px;
	}
	.student-content {
		width: 575px;
	}
	.student-content h3 {
		font-size: 36px;
		margin-bottom: 16px;
	}
	.student-wrap a {
		top: 82%;
	}
	.student-content p {
		font-size: 23px;
		line-height: 31px;
	}
	.student-aera {
		padding-top: 70px;
		padding-bottom: 70px;
	}
	.section-title h3 {
		font-size: 40px;
		margin-top: 2em;
		margin-bottom: 18px;
	}
	.Engage-title h3 {
		font-size: 40px;
		margin-bottom: 60px;
	}
	.Engage-blk img {
		margin-bottom: 36px;
		height: auto;
		width: 200px;
	}
	.choose-btn.choose-bty a {
		width: 230px;
		font-size: 20px;
	}
	.footer-list ul li a {
		font-size: 17px;
		line-height: 20px;
		margin-bottom: 20px;
	}
	.footer-body-text p {
		font-size: 15px;
		line-height: 24px;
	}
	.footer-social ul li a {
		font-size: 22px;
		margin-bottom: 11px;
	}
	.single-input input {
		font-size: 20px;
		margin-bottom: 24px;
	}
	.cheack-area {
		padding: 40px 0;
	}
	.cheack-blk {
		margin-bottom: 4em;
	}
	.section-title p {
		font-size: 20px;
		line-height: 32px;
		margin-bottom: 38px;
	}
	.slider-active .owl-nav button {
		left: 0;
	}
	.slider-active .owl-nav button.owl-next {
		left: auto;
		right: 0;
	}
	.client-fx {
		width: 100%;
	}
	.hero-containt a img {
		max-width: 200px;
	}
	.hero-containt h3 {
		font-size: 70px;
	}
	.hero-containt p {
		font-size: 40px;
	}
	.bd-video {
		height: 700px;
	}
	.feature-area {
        padding: 60px 0;
    }
    .feature-single a {
        width: 170px;
        height: 170px;
        line-height: 170px;
        border-radius: 50px;
        font-size: 72px;
    }
    .feature-text h3 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 9px;
    }
    .feature-text p {
        font-size: 16px;
        line-height: 31px;
        margin-bottom: 0;
    }
	.feature-fl {
		display: flex;
        padding: 0 20px;
    }
	.single-feature {
        width: 210px;
        text-align: center;
    }

	.feature-text h3 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 9px;
    }
    .feature-text p {
        font-size: 16px;
        line-height: 31px;
        margin-bottom: 0;
    }
	.work-area .work-all-fl {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 60px;
    }
    .work-area .single-work-box {
        width: 324px;
        height: 456px;
        padding: 45px 40px;
    }
    .work-area {
        padding-bottom: 50px;
    }
}

/* LG Device :992px. */

@media (min-width: 992px) and (max-width: 1200px) {
	.what-area {
        padding: 40px 0 !important;
    }
	.single-what {
		width: 370px !important;
		margin-bottom: 26px !important;
	}
	.what-text h3 {
        font-size: 20px;
        margin-bottom: 7px;
    }
    .what-text p {
        font-size: 15px !important;
        line-height: 28px !important;
    }
	.about-box-2 {
        width: 297px;
        padding-left: 24px;
        border-radius: 30px;
    }
    .about-box-title h4 {
        font-size: 19px;
        color: #fff;
        font-weight: 600;
        margin-bottom: 20px;
    }
    .about-wrap {
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 40px;
    }
	.benifit-area {
		background: #D5F2F8;
		padding-top: 50px;
		padding-bottom: 50px;
	}

	.benifit-area .single-benifit {
		padding: 22px 0;
	}
	.program-aera .program-fx-5 {
		width: 100%;
	}
	.program-aera .single-program {
		max-width: 50%;
		margin-bottom: 50px;
	}

	.video-wrapper{
		height: 420px;
	}

	#join-btn {
		margin-left: -0.25em;
	}
	.header-main-menu ul li a {
		font-size: 16px;
	}
	.hero-text {
		padding-top: 20%;
	}
	.hero-text span {
		font-size: 64px;
		line-height: 3rem;
		padding-right: 15%;
	}
	.header-main-menu ul li {
		padding-right: 40px;
	}
	.header-right-menu-blk ul li a {
		font-size: 16px;
	}
	.client-fx {
		width: 100%;
	}
	.header-right-menu-blk ul li {
		margin-left: 22px;
	}
	.header-logo {
		margin-left: 20px;
	}
	.section-title h2 {
		font-size: 40px;
	}
	.norm .why-area h2 {
		font-size: 40px;
	}
	.choose-content h3 {
		font-size: 28px !important;
		margin-top: 1em !important;
	}
	.choose-content h4 {
		font-size: 20px !important;
		margin-bottom: 1.5em !important;
	}
	.choose-content p {
		font-size: 16px !important;
		line-height: 23px !important;
		margin-bottom: 2em !important;
	}
	.why-area .section-title {
		margin-bottom: 10px;
	}
	.why-thumb img {
		height: auto;
	}
	.why-content h3 {
		font-size: 30px;
	}
	.why-content h4 {
		font-size: 21px;
		line-height: 31px;
		margin-bottom: 4px;
	}
	.why-inner {
		height: 62px;
	}
	.color-disd {
		color: #FF80B1 !important;
		margin-bottom: 4px !important;
	}
	.choose-area {
		padding: 31px 0;
	}
	.single-slider-item .slider-content h4 {
		font-size: 38px;
		line-height: 50px;
		margin-bottom: 13px;
	}
	.single-slider-item .slider-content h5 {
		font-size: 24px;
		margin-bottom: 13px;
	}
	.single-slider-item .slider-content p {
		font-size: 20px;
		line-height: 26px;
		padding-right: 0;
	}
	.sider-area {
		padding: 42px 0;
	}
	.single-far-brand {
		width: 30%;
		height: auto;
		border-radius: 40px;
		padding: 41px 10px;
		text-align: center;
		position: relative;
		margin: 0 16px;
		margin-bottom: 95px;
	}
	.single-far-brand::before {
		width: 400px;
	}
	.for-brand-fl {
		max-width: 100%;
	}
	.far-top-box p {
		font-size: 28px;
		line-height: 33px;
	}
	.far-area {
		padding-bottom: 0;
		padding-top: 45px;
	}
	.student-wrap {
		width: 100%;
		padding: 15px;
		padding-bottom: 40px;
	}
	.student-content {
		width: 575px;
	}
	.student-content h3 {
		font-size: 36px;
		margin-bottom: 16px;
	}
	.student-wrap a {
		top: 82%;
	}
	.student-content p {
		font-size: 18px;
		line-height: 25px;
	}
	.student-aera {
		padding-top: 30px;
		padding-bottom: 45px;
	}
	.section-title h3 {
		font-size: 40px;
		margin-bottom: 12px;
	}
	.Engage-title h3 {
		font-size: 40px;
		margin-bottom: 40px;
	}
	.Engage-blk img {
		margin-bottom: 36px;
		height: auto;
		width: 200px;
	}
	.choose-btn.choose-bty a {
		width: 189px;
		font-size: 17px;
	}
	.footer-list ul li a {
		font-size: 17px;
		line-height: 20px;
		margin-bottom: 20px;
	}
	.footer-body-text p {
		font-size: 15px;
		line-height: 24px;
	}
	.footer-social ul li a {
		font-size: 22px;
		margin-bottom: 11px;
	}
	.single-input input {
		font-size: 20px;
		margin-bottom: 24px;
	}
	.cheack-area {
		padding: 40px 0;
	}
	.section-title p {
		font-size: 20px;
		line-height: 30px;
		margin-bottom: 38px;
	}
	.slider-active .owl-nav button {
		left: 0;
	}
	.slider-active .owl-nav button.owl-next {
		left: auto;
		right: 0;
	}
	.choose-btn a {
		width: 289px;
		height: 53px;
		line-height: 47px;
		font-size: 20px;
	}
	.single-slider-item .slider-content h4 br {
		display: none;
	}
	.far-area .section-title {
		margin-bottom: 45px;
	}
	.far-top-box {
		width: 228px;
		height: 170px;
		padding: 0 20px;
	}
	.for-brand-fl {
		max-width: 100%;
		margin: auto;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}
	.single-far-brand span {
		height: 20px;
		width: 20px;
		left: -34px;
	}
	.single-far-brand span:nth-child(2) {
		right: -33px;
		left: auto;
	}
	.student-title h3 {
		line-height: 80px;
		font-size: 40px;
	}
	.student-hat img {
		height: 80px;
	}
	.footer-widget h4 {
		font-size: 17px;
		margin-bottom: 25px;
	}
	.hero-containt a img {
		max-width: 200px;
	}
	.hero-containt h3 {
		font-size: 60px;
	}
	.hero-containt p {
		font-size: 32px;
	}
	.bd-video {
		height: 600px;
	}
	.feature-area {
        padding: 60px 0;
    }
    .feature-single a {
        width: 120px;
        height: 120px;
        line-height: 120px;
        border-radius: 33px;
        font-size: 55px;
    }
    .feature-text h3 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 9px;
    }
    .feature-text p {
        font-size: 15px;
        line-height: 24px;
        margin-bottom: 0;
    }

	.feature-area .single-feature {
        width: 210px;
        text-align: center;
    }
	.work-area .work-all-fl {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
    }
    .work-area .single-work-box {
        width: 297px;
        height: 426px;
        padding: 20px 25px;
    }
    .work-area {
        padding-bottom: 50px;
    }
}

/* MD Device :768px. */

@media (min-width: 768px) and (max-width: 991px) {
	.what-area {
		padding: 40px 0 !important;
	}
	.what-fl {
		padding: 0 50px !important;
	}
	.single-what {
		width: 313px !important;
		margin-bottom: 26px !important;
	}
	.what-text h3 {
		font-size: 20px;
		margin-bottom: 7px;
	}
	.what-text p {
		font-size: 15px !important;
		line-height: 28px !important;
	}
	.about-wrap {
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 18px;
    }
	.about-box-2 {
        width: 297px;
        padding-right: 90px;
        border-radius: 30px;
    }
    .about-box-2 h4 {
        font-size: 19px;
        color: #fff;
        font-weight: 600;
        margin-bottom: 20px;
    }
	.about-box-2 p {
		font-size: 14px;
		color: #000000;
		margin-bottom: 21px;
	}

	.about-img {
		position: absolute;
		left: 21%;
		width: 166px;
		top: 50%;
		transform: translateY(-50%);
	}
	.about-img.image-df {
		position: absolute;
		right: 13%;
		left: auto;
		top: 50%;
		transform: translateY(-50%);
	}
	.about-wrap .about-text-box {
		width: 770px;
		background: #E7F5FE;
		padding-top: 26px;
		padding-left: 64px;
		padding-bottom: 23px;
		padding-right: 28px;
		border-radius: 24px;
		position: relative;
		z-index: -1;
	}
	.about-wrap .about-text-box h4 {
        font-size: 19px;
        color: #fff;
        font-weight: 600;
        margin-bottom: 20px;
    }
	.about-wrap .about-text-box p {
		font-size: 14px;
		color: #000000;
		margin-bottom: 21px;
	}

	.benifit-area {
		background: #D5F2F8;
		padding-top: 50px;
		padding-bottom: 50px;
	}
	.benifit-area .benifit-fx {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}
	.benifit-area .single-benifit {
		padding: 22px 0;
		width: 29%;
		margin: 0 15px;
		margin-bottom: 30px;
	}
	.program-aera .program-fx-5 {
		width: 100%;
	}
	.program-aera .single-program {
		width: 1009px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-left: 15%;
	}
	
	.video-wrapper{
		height: 350px;
	}

	#join-btn {
		margin-left: -0.25em;
	}
	.client-fx {
		width: 100%;
	}
	.hero-text {
		padding-top: 10%;
	}
	.hero-text span {
		font-size: 32px;
		line-height: 3rem;
	}
	.navbar-light .navbar-toggler {
		color: #7ce3e8;
		border-color: #ffc107;
		margin-right: 3rem;
	}
	.header-main-menu ul li a {
		font-size: 16px;
	}
	.header-main-menu ul li {
		padding-right: 23px;
	}
	.header-right-menu-blk ul li a {
		font-size: 16px;
	}
	.header-area {
		height: 88px;
	}
	.header-right-menu-blk ul li {
		margin-left: 22px;
	}
	.header-logo {
		margin-left: 2px;
	}
	.section-title h2 {
		font-size: 36px;
	}
	.norm .why-area h2 {
		font-size: 36px;
	}
	.choose-content h3 {
		font-size: 28px !important;
	}
	.choose-content h4 {
		font-size: 20px !important;
		margin-bottom: 11px !important;
	}
	.choose-content p {
		font-size: 16px !important;
		line-height: 23px !important;
		margin-bottom: 20px !important;
		height: 64px !important;
	}
	.why-area .section-title {
		margin-bottom: 10px;
	}
	.why-thumb img {
		height: auto;
	}
	.why-content h3 {
		font-size: 28px;
	}
	.why-content h4 {
		font-size: 18px;
		line-height: 22px;
		margin-bottom: 4px;
	}
	.why-inner {
		height: 62px;
	}
	.color-disd {
		color: #FF80B1 !important;
		margin-bottom: 4px !important;
	}
	.choose-area {
		padding: 31px 0;
	}
	.single-slider-item .slider-content h4 {
		font-size: 28px;
		line-height: 55px;
		margin-bottom: 2px;
	}
	.single-slider-item .slider-content h5 {
		font-size: 24px;
		margin-bottom: 13px;
	}
	.single-slider-item .slider-content p {
		font-size: 20px;
		line-height: 26px;
		padding-right: 0;
	}
	.sider-area {
		padding: 42px 0;
	}
	.single-far-brand {
		width: 28%;
		height: 360px;
		border-radius: 40px;
		padding: 41px 10px;
		margin: 0 16px;
		margin-bottom: 95px;
	}
	.single-far-brand::before {
		width: 251px;
	}
	.for-brand-fl {
		max-width: 100%;
	}
	.far-top-box p {
		font-size: 21px;
		line-height: 29px;
	}
	.far-area {
		padding-bottom: 0;
		padding-top: 45px;
	}
	.student-wrap {
		width: 100%;
		padding: 15px;
		padding-bottom: 40px;
	}
	.student-content {
		width: 575px;
	}
	.student-content h3 {
		font-size: 30px;
		margin-bottom: 16px;
	}
	.student-wrap a {
		top: 82%;
	}
	.student-content p {
		font-size: 18px;
		line-height: 25px;
	}
	.student-aera {
		padding-top: 30px;
		padding-bottom: 45px;
	}
	.section-title h3 {
		font-size: 36px;
		margin-bottom: 12px;
	}
	.Engage-title h3 {
		font-size: 36px;
		margin-bottom: 40px;
	}
	.Engage-blk img {
		height: auto;
		width: 200px;
	}
	.choose-btn.choose-bty a {
		width: 189px;
		font-size: 17px;
	}
	.footer-list ul li a {
		font-size: 17px;
		line-height: 20px;
		margin-bottom: 20px;
	}
	.footer-body-text p {
		font-size: 15px;
		line-height: 24px;
	}
	.footer-social ul li a {
		font-size: 22px;
		margin-bottom: 11px;
	}
	.single-input input {
		font-size: 20px;
		margin-bottom: 24px;
	}
	.cheack-area {
		padding: 40px 0;
	}
	.section-title p {
		font-size: 18px;
		line-height: 30px;
		margin-bottom: 19px;
	}
	.slider-active .owl-nav button {
		left: 0;
	}
	.slider-active .owl-nav button.owl-next {
		left: auto;
		right: 0;
	}
	.choose-btn a {
		width: 220px;
		height: 45px;
		line-height: 39px;
		font-size: 16px;
	}
	.single-slider-item .slider-content h4 br {
		display: none;
	}
	.far-area .section-title {
		margin-bottom: 45px;
	}
	.far-top-box {
		width: 174px;
		height: 170px;
		padding: 0 1px;
	}
	.for-brand-fl {
		max-width: 100%;
		margin: auto;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}
	.single-far-brand span {
		height: 20px;
		width: 20px;
		left: -34px;
	}
	.single-far-brand span:nth-child(2) {
		right: -33px;
		left: auto;
	}
	.student-title h3 {
		line-height: 68px;
		font-size: 35px;
	}
	.student-hat img {
		height: 80px;
	}
	.section-title {
		margin-bottom: 30px;
	}
	.footer-widget h4 {
		font-size: 17px;
		margin-bottom: 25px;
	}
	.header-logo a img {
		height: 68px;
	}
	.why-content p {
		font-size: 16px;
		line-height: 24px;
		margin: 0;
	}
	.why-area {
		padding-bottom: 35px;
		padding-top: 30px;
	}
	.Engage-area {
		padding-bottom: 30px;
		padding-top: 20px;
	}
	.hero-containt a img {
		max-width: 200px;
	}
	.hero-containt h3 {
		font-size: 52px;
	}
	.hero-containt p {
		font-size: 32px;
	}
	.hero-containt a {
		text-align: left;
	}
	.bd-video {
		height: 600px;
	}

	.feature-area {
        padding: 60px 0;
    }

    .feature-single a {
        width: 120px;
        height: 120px;
        line-height: 120px;
        border-radius: 33px;
        font-size: 55px;
    }

    .feature-text h3 {
        font-size: 40px;
        font-weight: 600;
        margin-bottom: 9px;
    }
    .feature-text p {
        font-size: 13px;
        line-height: 24px;
        margin-bottom: 0;
    }
	.single-feature {
        width: 210px;
        text-align: center;
    }
	.work-area .work-all-fl {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
    }
    .work-area.single-work-box {
        width: 226px;
        height: 380px;
        padding: 13px 19px;
    }
    .work-area {
        padding-bottom: 50px;
    }
}

/* SM Small Device :320px. */

@media only screen and (min-width: 100px) and (max-width: 767px) {
	#hero h3{
		font-size: 20px;
	}
	.slider-content h4{
		font-size: 32px !important;
		line-height: 24px !important;
	}

	.slider-content h5{
		font-size: 20px !important;
	}

	.slider-content p{
		font-size: 15px !important;
		padding-right: 0 !important;

	}

	.team-boxed .item .box {
		width: 100% !important;
	}

	.what-area {
		padding: 40px 0 !important;
	}
	.what-fl {
		padding: 0 !important;
	}
	.feature-area {
        padding: 40px 0 !important;
    }
	.single-what {
		width: 100% !important;
		margin-bottom: 26px !important;
		text-align: center !important;
	}
	.what-text h3 {
		font-size: 20px;
		margin-bottom: 7px;
	}
	.what-text p {
		font-size: 15px !important;
		line-height: 28px !important;
	}
	.feature-fl {
        padding: 0 !important;
		display: block !important;
		flex-direction: column;
    }
	
    .single-feature {
        width: 100% !important;
        text-align: center !important;
        margin-bottom: 30px;
    }
	
    .single-feature .feature-text h3 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
    }
    .single-feature .feature-text p {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 0;
    }

	.about-wrap {
		display: flex;
		flex-direction: column;
        align-items: center;
        position: relative;
        margin-bottom: 26px;
    }
	
	.about-wrap .about-box-2 {
        width: 100%;
		margin-right: 0;
		padding-left: 30px;
        border-radius: 30px;
        margin-bottom: 30px;
		text-align: left;
    }

	.about-wrap .about-img {
		position: absolute;
		left: auto;
		right: 0;
		top: 24%;
		transform: translateY(-50%);
		max-width: 141px;
	}

	.about-wrap .about-img.image-df {
		position: absolute;
		right: auto;
		left: 11px;
		top: 66%;
		transform: translateY(-50%);
	}

	.about-wrap .about-box-2.box-3 {
        text-align: right;
        padding-left: 0;
		padding-right: 30px;
        margin-left: 0;
        margin-right: 0;
        background: #FDE16F;
    }

	.about-wrap .about-text-box {
		width: 100%;
		background: #E7F5FE;
		padding-top: 21px;
		padding-left: 30px;
		padding-bottom: 20px;
		padding-right: 20px;
		border-radius: 21px;
		position: relative;
		z-index: -1;
	}
	.about-wrap .about-text-box h4 {
        font-size: 19px;
        color: #fff;
        font-weight: 600;
        margin-bottom: 20px;
    }
	.about-wrap .about-text-box p {
		font-size: 14px;
		color: #000000;
		margin-bottom: 21px;
	}
	.about-box-2.box-3.mobile-scrn {
        display: block;
        width: 100%;
        margin-left: 0;
    }
	.about-box-2.box-3.mobile-scrn {
        display: block;
    }
	.post-preview h2 {
		font-size: 28px !important;
	}
	.benifit-area {
		background: #D5F2F8;
		padding-top: 50px;
		padding-bottom: 50px;
	}
	.benifit-area .benifit-fx {
		display: block;
		justify-content: center;
		flex-wrap: wrap;
	}
	.benifit-area .single-benifit {
		padding: 22px 0;
		width: 100%;
		margin: 0;
		margin-bottom: 30px;
	}
	.program-aera .program-fx-5 {
		width: 100%;
	}
	.program-aera .single-program {
		width: 100%;
		display: block;
	}
	
	#hdrtxt h3{
		font-size: 18px;
	  }
	  #hdrtxt h1{
		font-size: 28px;
		padding:10%;
	  }
	.video-wrapper{
		height: 250px;
	}

	#join-btn {
		margin-left: -0.25em;
	}
	.site-heading {
		margin-left: 2em;
	}
	.header-main-menu {
		display: none;
	}
	.hero-text {
		padding-top: 10%;
	}
	.hero-text span {
		font-size: 32px;
		line-height: 3rem;
	}
	.client-fx {
		width: 100%;
		flex-wrap: wrap;
	}
	.header-right-menu-blk ul li {
		margin-left: 0;
	}
	.header-right-menu-blk {
		display: none;
	}
	.bar {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 28px;
		z-index: 9;
	}
	.bar a {
		font-size: 23px;
		color: #000;
	}
	.offcanva-menu ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}
	.offcanva-menu ul li a {
		font-size: 17px;
		padding: 11px 15px;
		display: block;
		color: #000;
		text-transform: capitalize;
		border-bottom: 1px solid #ddd9;
		transition: .3s;
	}
	.offcanva-menu ul li a:hover {
		color: #36C7CE;
		border-color: #36C7CE;
		padding-left: 31px;
	}
	.offcanva {
		position: fixed;
		width: 90%;
		height: 100%;
		background: #fff;
		z-index: 33;
		transition: .3s;
		left: -100%;
	}
	.overlay {
		width: 100%;
		height: 100%;
		background: #000;
		position: fixed;
		opacity: .3;
		z-index: 9;
		top: 0;
		left: -100%;
		transition: .3s;
	}
	.offcanva-menu {
		margin-top: 93px;
	}
	.cross {
		position: absolute;
		top: 10px;
		right: 21px;
	}
	.cross a {
		font-size: 25px;
		color: #000;
	}
	.offcanva.active {
		left: 0;
	}
	.overlay.active {
		left: 0;
	}
	.offcanva {
		display: block;
	}
	.bar {
		display: block;
	}
	.bar a {
		color: #FFC600;
	}
	.header-logo {
		margin-left: 0;
		position: relative;
	}
	.header-area {
		height: 88px;
	}
	.header-main-menu ul li a {
		font-size: 16px;
	}
	.header-main-menu ul li {
		padding-right: 23px;
	}
	.header-right-menu-blk ul li a {
		font-size: 16px;
	}
	.header-right-menu-blk ul li {
		margin-left: 22px;
	}
	.header-logo {
		margin-left: 2px;
	}
	.section-title h2 {
		font-size: 26px;
	}
	.norm .why-area h2 {
		font-size: 26px;
	}
	.choose-content h3 {
		font-size: 25px !important;
		margin-bottom: 10px !important;
	}
	.choose-content h4 {
		font-size: 20px !important;
		margin-bottom: 11px !important;
	}
	.choose-content p {
		font-size: 16px !important;
		line-height: 23px !important;
		margin-bottom: 20px !important;
		height: auto;
	}
	.why-area .section-title {
		margin-bottom: 10px;
	}
	.why-thumb img {
		height: auto;
	}
	.why-content h3 {
		font-size: 28px;
	}
	.why-content h4 {
		font-size: 18px;
		line-height: 22px;
		margin-bottom: 4px;
	}
	.why-inner {
		height: 62px;
	}
	.color-disd {
		color: #FF80B1 !important;
		margin-bottom: 4px !important;
	}
	.navbar-brand {
		font-size: 32px !important;
		font-weight: 600 !important;
		color: white;
	}
	.choose-area {
		padding: 31px 0;
	}
	.single-slider-item .slider-content h4 {
		font-size: 24px;
		line-height: 48px;
		margin-bottom: 2px;
	}
	.single-slider-item .slider-content h5 {
		font-size: 20px;
		margin-bottom: 13px;
	}
	.single-slider-item .slider-content p {
		font-size: 16px;
		line-height: 22px;
		padding-right: 0;
	}
	.sider-area {
		padding: 42px 0;
	}
	.single-far-brand {
		width: 226px;
		height: 360px;
		border-radius: 40px;
		padding: 41px 10px;
		margin: auto;
		margin-bottom: 84px;
	}
	.single-far-brand::before {
		width: 264px;
	}
	.for-brand-fl {
		max-width: 100%;
	}
	.far-top-box p {
		font-size: 21px;
		line-height: 29px;
	}
	.far-area {
		padding-bottom: 0;
		padding-top: 45px;
	}
	.student-wrap {
		width: 100%;
		padding: 15px;
		padding-bottom: 40px;
		position: relative;
	}
	.student-content {
		width: 100%;
	}
	.single-far-brand.b2 {
		margin-bottom: 30px;
	}
	.student-content h3 {
		font-size: 30px;
		margin-bottom: 16px;
	}
	.student-wrap a {
		top: 19%;
	}
	.student-content p {
		font-size: 18px;
		line-height: 25px;
	}
	.student-aera {
		padding-top: 30px;
		padding-bottom: 45px;
	}
	.section-title h3 {
		font-size: 28px;
		margin-bottom: 8px;
	}
	.Engage-title h3 {
		font-size: 33px;
		margin-bottom: 40px;
	}
	.Engage-blk img {
		margin-bottom: 44px;
		height: 200px;
		width: 200px;
	}
	.choose-btn.choose-bty a {
		width: 189px;
		font-size: 17px;
	}
	.footer-list ul li a {
		font-size: 17px;
		line-height: 20px;
		margin-bottom: 10px;
	}
	.footer-body-text p {
		font-size: 15px;
		line-height: 24px;
		margin: 0;
	}
	.footer-social ul li a {
		font-size: 22px;
		margin-bottom: 11px;
	}
	.footer-area {
		padding: 0;
		padding-top: 40px;
		padding-bottom: 0;
	}
	.footer-social ul li {
		display: inline-block;
		margin: 0 12px;
	}
	.footer-social ul {
		padding: 0;
	}
	.footer-blk {
		text-align: center;
		margin-bottom: 40px;
	}
	.student-wrap a {
		display: block;
		width: 200px;
		text-align: center;
		line-height: 42px;
		height: 45px;
		font-size: 18px;
		top: auto;
		bottom: -15px;
		right: 20px;
	}
	.single-input input {
		font-size: 20px;
		margin-bottom: 24px;
	}
	.choose-btn {
		margin-bottom: 28px;
	}
	.footer-logo {
		margin-bottom: 29px;
	}
	.cheack-area {
		padding: 40px 0;
	}
	.section-title p {
		font-size: 16px;
		line-height: 26px;
		margin-bottom: 19px;
	}
	.slider-active .owl-nav button {
		left: 0;
	}
	.slider-active .owl-nav button.owl-next {
		left: auto;
		right: 0;
	}
	.choose-btn a {
		width: 243px;
		height: 45px;
		line-height: 39px;
		font-size: 18px;
	}
	.single-slider-item .slider-content h4 br {
		display: none;
	}
	.dropdown, .dropleft, .dropright, .dropup {
		position: relative !important;
		padding-left: 3rem !important;
		color: white;
	}
	.navbar-light .navbar-nav .nav-link {
		color: white;
	}
	.navbar-light .navbar-toggler {
		color: #7ce3e8 !important;
		border-color: #ffc107 !important;
	}
	.far-area .section-title {
		margin-bottom: 45px;
	}
	.far-top-box {
		width: 100%;
		height: 170px;
		padding: 0 1px;
		margin: 0 auto;
		margin-bottom: 30px;
	}
	.for-brand-fl {
		max-width: 99%;
		margin: auto;
		display: block;
		justify-content: center;
		flex-wrap: wrap;
	}
	.single-far-brand span {
		height: 20px;
		width: 20px;
		left: -34px;
	}
	.single-far-brand span:nth-child(2) {
		right: -33px;
		left: auto;
	}
	.far-top-fl {
		max-width: 1180px;
		margin: auto;
		display: block;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 80px;
	}
	.video-wrapper {
		margin-bottom: 22px;
	}
	.student-title h3 {
		line-height: 68px;
		font-size: 36px;
	}
	.student-hat img {
		height: 80px;
	}
	.section-title {
		margin-bottom: 30px;
	}
	.footer-widget h4 {
		font-size: 17px;
		margin-bottom: 8px;
	}
	.header-logo a img {
		height: 68px;
	}
	.why-content p {
		font-size: 16px;
		line-height: 24px;
		margin: 0;
	}
	.student-hat img {
		height: 60px;
		margin-bottom: 20px;
	}
	.Engage-area {
		padding-bottom: 30px;
		padding-top: 20px;
	}
	.choose-content {
		padding: 18px !important;
	}
	.why-content {
		margin-bottom: 20px;
	}
	.slider-active .owl-nav button {
		display: none;
	}
	.single-far-brand.b2 {
		margin-bottom: 30px;
	}
	.box-fx {
		display: block;
	}
	.student-content.cnt-2 h3 {
		font-size: 24px;
		margin-bottom: 10px;
	}
	.hero-containt a img {
		max-width: 120px;
		text-align: center;
		margin: auto;
	}
	.hero-containt h3 {
		font-size: 30px;
		text-align: center;
	}
	.hero-containt p {
		font-size: 16px;
		text-align: center;
	}
	.bd-video {
		height: 400px !important;
	}
	.bg-videp {
		height: 100%;
	}
	#myVideo {
		height: 100%;
		background-size: cover;
		object-fit: cover;
	}
	.hero-containt a {
		text-align: center;
	}
	.single-client {
		width: 50%;
		text-align: center;
	}
	.bd-video {
		height: 700px;
	}
	.work-area {
        padding-bottom: 0 !important;
    }
	.work-area .work-all-fl {
        display: block;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        text-align: center;
    }
	.single-work-box {
        width: 100% !important;
		height: 550px !important;
		padding: 13px 19px !important;
		margin-bottom: 30px;
    }
}